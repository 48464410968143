const dictionary = {
    DeltaDays: 'Aantal dagen',
    BeginDay: 'Start met dag',
    EndDay: 'Eindigt met dag',
    ContainsDay: 'Bevat dag',
    DateRange: 'Datum bereik',
    SingleDate: 'Datum',
    DiscountCode: 'Kortingscode',
    DaysBeforeBooking: 'Aantal dagen voor de boeking',
    AccommodationType: 'Accommodatie type',
    Accommodation: 'Accommodatie',
    ReturningVisitor: 'Herhalend bezoek',
    NumberOfTravellers: 'Aantal reizigers',
    ContainsTravellerType: 'Bevat type reiziger',
    PreferredPlace: 'Voorkeursplek',
    IsHomeOwner: 'Hoofdboeker is eigenaar',

    DateFormats: {
        ShortDate: 'DD-MM-YYYY',
        FullDate: 'DD-MM-YYYY',
    },

    Travellers: {
        ZeroUntilTwoYears: '0-2 jaar',
        ChildThreeYearsAndUp: 'Kinderen van 3 jaar en ouder',
        Adult: 'Volwassenen',
        Senior: 'Senior (65+)',
        Pet: 'Huisdier',
    },

    TravellersTypes: {
        1: '0-2 jaar',
        2: 'Kinderen van 3 jaar en ouder',
        3: 'Volwassenen',
        4: 'Senior (65+)',
        5: 'Huisdier',
    },

    Days: {
        Monday: 'Maandag',
        Tuesday: 'Dinsdag',
        Wednesday: 'Woensdag',
        Thursday: 'Donderdag',
        Friday: 'Vrijdag',
        Saturday: 'Zaterdag',
        Sunday: 'Zondag',
    },

    ProductTypes: {
        WholeBooking: 'Boeking',
        AccoPerNight: 'Accommodatie, Per nacht',
        SpecificTraveller: 'Specifieke reiziger',
        AllTravellersPerNight: 'Per reiziger, Per nacht',
        PhysicalProduct: 'Fysiek product',
    },

    BookingStates: {
        1: 'Gereserveerd',
        2: 'Affiliate Gereserveerd',
        3: 'Bevestigd',
        4: 'Afgewezen',
        5: 'Betaald',
        6: 'Ingecheckt',
        7: 'Uitgecheckt',
        8: 'Geannuleerd',
    },

    InvoiceStates: {
        0: 'Onbekend',
        1: 'Concept',
        2: 'Open',
        3: 'Deels Betaald',
        4: 'Betaald',
        5: 'Betalingstermijn verstreken',
        6: 'Verwijderd',
        7: 'Gecrediteerd',
    },
};

export default dictionary;
