import { brightService, loginHelper } from '@/main';
import { profileModule } from '@/store/profile';
import { Membership } from '../models/Interfaces';

export class EcosystemHelper {
    public _currentEcosystem: Membership = null;
    public setupIsDone: boolean = false;
    public structure: any = null;

    public async setup() {
        let ecosystemId = null;
        const urlSegments = window.location.pathname.split('/');

        if (typeof urlSegments === 'object' && urlSegments.length > 1) {
            ecosystemId = parseInt(urlSegments[1].split('-')[0]);
        }
        this.setEcosystemByEcosystemId(ecosystemId);
        this.setupIsDone = true;
    }

    public getEcosystem(id?: number) {
        if (!id && this._currentEcosystem) {
            id = this._currentEcosystem.id;
        }
        const user = profileModule.brightProfile;
        let ecosystem = user.ecosystemMemberships.find((x) => x.id === id);
        if (ecosystem) {
            return ecosystem;
        }

        ecosystem = user.ecosystemMemberships.find((x) => x.id === user.primaryEcosystem);
        if (ecosystem) {
            return ecosystem;
        }

        return user.ecosystemMemberships[0];
    }

    public setEcosystemByEcosystemId(id: number) {
        this._currentEcosystem = this.getEcosystem(id);
    }

    public getEcosystemById(id: number): any {
        const user = profileModule.brightProfile;
        let ecosystem = user.ecosystemMemberships.find((x) => x.id === id);
        if (ecosystem) {
            return ecosystem;
        }

        ecosystem = user.ecosystemMemberships.find((x) => x.id === user.primaryEcosystem);
        if (ecosystem) {
            return ecosystem;
        }

        return user.ecosystemMemberships[0];
    }

    public getEcosystemName(id: number): any {
        const user = loginHelper.getUser();
        const team = user.ecosystemMemberships.find((membership) => {
            return membership.id === id;
        });

        if (team) {
            return team.name;
        }
        return user.ecosystemMemberships[0].name;
    }

    public async loadStructure() {
        const structure = await brightService.getStructure(this.currentEcosystem.id);
        this.structure = structure;
    }

    get currentEcosystem(): Membership {
        return this._currentEcosystem;
    }

    get currentEcosystemId(): number {
        return this._currentEcosystem.id;
    }

    get ecosystemParam(): string {
        return `${this._currentEcosystem.id}-${this._currentEcosystem.key}`;
    }
}
