import { BaseService } from './baseService';
import { AxiosHelper } from '../helpers/axiosHelper';
import Vue from 'vue';
import Source from '@/models/Source';
import to from 'await-to-js';

export class SourcesService extends BaseService {
    private endpoint = Vue.$env().MiddlewareAPIEndpoint;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getSources() {
        const [err, response] = await to(this.get<Source[]>(`${this.endpoint}sources`));
        if (err) {
            this.showError('Failed to retrieve sources');
            return [];
        }

        const sources = response.data;
        return sources.map((source) => {
            return new Source(source);
        });
    }
}
