import { Component } from 'vue-property-decorator';
import BaseFilter from './BaseFilter';

@Component
export default class DateFilterComponent extends BaseFilter {
    public changeDate(ev: any) {
        this.$emit('change', {
            operator: this.getOperator(),
            field: this.field,
            value: this.filterValue,
            syntheticEvent: ev,
        });
    }

    public resetDate(ev: any) {
        this.filterValue = null;
        this.$emit('change', {
            operator: '',
            field: '',
            value: '',
            syntheticEvent: ev,
        });
    }

    private getOperator(): string {
        const field = this.field.toLocaleLowerCase();
        if (field.indexOf('arrival')) {
            return 'lte'; // Is before or equal to
        }

        if (field.indexOf('departure')) {
            return 'gte'; // Is after or equal to
        }
    }
}
