import to from 'await-to-js';
import { sourcesService } from '@/main';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import store from '../store';
import Source from '@/models/Source';
import Provider from '@/models/Provider';
import { ISourceAction, IConsumerAction } from '@/models/Interfaces';
import Consumer from '@/models/Consumer';
import { consumerModule } from './Consumer';

@Module
class SourceModule extends VuexModule {
    public sources: Source[] = [];
    public failed: boolean = false;

    get all() {
        return this.sources;
    }

    public get inputProviders(): Provider[] {
        const providers = [];
        this.sources.forEach((source: Source) => {
            providers.push(...source.inputProviders);
        });

        return providers;
    }

    public get outputProviders(): Provider[] {
        const providers = [];
        this.sources.forEach((source: Source) => {
            providers.push(...source.outputProviders);
        });

        return providers;
    }

    public get actions(): ISourceAction[] {
        const actions = [];
        this.sources.forEach((source: Source) => {
            source.actions.forEach((action) => {
                const sourceAction: ISourceAction = {
                    name: action.name,
                    description: action.description,
                    inputProviders: [],
                    outputProviders: [],
                    settings: [],
                    sourceName: source.name,
                };

                source.inputProviders.forEach((inputProvider) => {
                    sourceAction.inputProviders.push(inputProvider.name);
                });
                source.outputProviders.forEach((outputProvider) => {
                    sourceAction.outputProviders.push(outputProvider.name);
                });

                sourceAction.settings = action.settings;
                actions.push(sourceAction);
            });
        });

        return actions;
    }

    public get consumerActions() {
        return (direction?: number) => {
            const items: IConsumerAction[] = [];

            this.actions.forEach((action: ISourceAction) => {
                let actionProviders = [];
                if (direction) {
                    actionProviders = direction > 0 ? action.inputProviders : action.outputProviders;
                } else {
                    actionProviders.push(...action.inputProviders);
                    actionProviders.push(...action.outputProviders);
                }
                const consumerProviders = consumerModule.currentProviders.filter((provider: Provider) => {
                    return actionProviders.indexOf(provider.provider) > -1;
                });

                if ((consumerProviders && consumerProviders.length > 0) || action.name.startsWith('Mutations.')) {
                    items.push({
                        name: action.name,
                        description: `${action.description} (${action.sourceName})`,
                        providers: consumerProviders,
                        settings: action.settings,
                    });
                }
            });
            return items;
        };
    }

    @Mutation
    public fail() {
        this.sources = [];
        this.failed = true;
    }

    @Mutation
    public set(payload: Source[]) {
        this.sources = payload;
        this.failed = false;
    }

    @Action
    public async fetch() {
        const items = await sourcesService.getSources();
        this.set(items);
    }

    @Action
    public async fetchIfNeeded() {
        if (this.sources.length === 0) {
            return this.fetch();
        }
    }
}

export const sourceModule = new SourceModule({ store, name: 'source' });
