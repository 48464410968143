import Provider from './Provider';
import Path from './Path';
import Step from './Step';

export default class Consumer {
    public id: string;
    public name: string;
    public providers: Provider[] = [];
    public paths: Path[] = [];

    constructor(init?: Partial<Consumer>) {
        Object.assign(this, init);
    }

    public getProvider(providerId: string) {
        return this.providers.find((provider) => provider.id === providerId);
    }

    public getProvidersByName(providerName: string) {
        return this.providers.filter((cProv) => cProv.provider === providerName);
    }

    public getPathByStepId(stepId): Path {
        return this.paths.find((path) => path.steps.filter((x) => x.id === stepId).length > 0);
    }

    public getStepByStepId(stepId): Step {
        const foundPath = this.getPathByStepId(stepId);
        return foundPath.steps.find((x) => x.id === stepId);
    }
}
