import Credentials from './Credentials';
import { IProviderConfiguration } from './Providers/IProviderConfiguration';

export default class Provider {
    public id: string;
    public name: string = '';
    public credentials: Credentials;
    public provider: string = '';
    public credentialsStored: boolean;
    public direction: number;
    public consumerId: string;
    public providerConfiguration: IProviderConfiguration = null;
    public defaults: ProviderDefaults = new ProviderDefaults();
    public configurationFields: any[] = null;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}

// tslint:disable: max-classes-per-file
export class ProviderDefaults {
    public invoiceLine: InvoiceLineDefaults = new InvoiceLineDefaults();
}

export class InvoiceLineDefaults {
    public department: DefaultEntity = new DefaultEntity();
    public project: DefaultEntity = new DefaultEntity();
    public ledgerAccount: DefaultEntity = new DefaultEntity();
}

export class DefaultEntity {
    public id: string = '';
    public name: string = '';
}
