import { UserRoles } from '../models/UserRoles';
import { loginService } from '@/main';
import { profileModule } from '@/store/profile';

/**
 * Helper function to assist in checking user roles.
 */
export class RoleHelper {
    /**
     * Check if a user belongs to a role for the target.
     * @param role Role to check.
     */
    public userHasRole(role: UserRoles) {
        return profileModule.getRoles().indexOf(role) > -1;
    }

    public isInvoicingSystemAdmin(): boolean {
        return this.userHasRole(UserRoles.InvoicingSystemAdmin);
    }

    public isInvoicingFinancialEmployee(): boolean {
        return this.userHasRole(UserRoles.InvoicingFinancialEmployee);
    }

    public isSiteAdmin(): boolean {
        return this.userHasRole(UserRoles.SiteAdmin);
    }
}
