import { Component, Prop } from 'vue-property-decorator';
import { loginHelper, loginService } from '@/main';
import PageRender from '../../models/PageRender';
import { consumerModule } from '@/store/Consumer';
import Path from '@/models/Path';

@Component
export default class NavMenuComponent extends PageRender {
    public isLoading: boolean = true;
    public isNewLoading: boolean = false;
    public currentMenuComponent: string = 'default-menu';
    public showNavigationContainer: boolean = true;
    public user: any = {};
    public isEcoScope: boolean = true;

    public async created() {
        this.isEcoScope = !this.$route.params.teamId;
        this.isLoading = false;
    }

    public get pathLinks() {
        if (this.$route.params.teamId) {
            const consumer = consumerModule.find(this.$route.params.consumer);
            if (consumer) {
                return consumerModule.currentPaths;
            }
        }

        return [];
    }

    public get consumerModule() {
        return consumerModule;
    }

    public toggleNavigationContainer() {
        this.showNavigationContainer = !this.showNavigationContainer;
    }

    public isActive(globalName: string) {
        return this.getGlobalName() === globalName;
    }

    public getGlobalName() {
        return this.$route.meta && this.$route.meta.global ? this.$route.meta.global : this.$route.name;
    }

    public getScopeVariant() {
        if (this.isActive('invoices')) {
            return 'success';
        }

        return 'primary';
    }

    public showIcons() {
        return loginHelper.isLoggedIn();
    }

    public pathIsActive(pathLink: Path) {
        return this.$route.name === 'sync-path-detail' && this.$route.params.id === pathLink.id;
    }
}
