import { BaseService } from './baseService';
import { AxiosHelper } from '../helpers/axiosHelper';
import Vue from 'vue';
import Consumer from '@/models/Consumer';
import ImportedInvoice from '@/models/ImportedInvoice';
import { InvoiceType } from '@/models/InvoiceType';
import Path from '@/models/Path';
import Provider from '@/models/Provider';

export class ConsumerService extends BaseService {
    private endpoint = Vue.$env().MiddlewareManagementAPIEndpoint;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public getConsumers() {
        return this.get<Consumer[]>(`${this.endpoint}consumers`);
    }

    public getInvoicesOfConsumer(consumer: string, type: InvoiceType = InvoiceType.Income) {
        return this.get<ImportedInvoice[]>(`${this.endpoint}consumers/:consumer/invoices?invoice_type=:type`, [
            ['consumer', consumer],
            ['type', type],
        ]);
    }

    public updateInvoice(consumer: string, invoice: ImportedInvoice) {
        return this.post<ImportedInvoice[]>(`${this.endpoint}consumers/:consumer/invoices/:invoiceId`, invoice, [
            ['consumer', consumer],
            ['invoiceId', invoice.invoice.id],
        ]);
    }

    public deleteImportedInvoice(consumer: string, selectedInvoices: ImportedInvoice[]) {
        const invoiceIds = selectedInvoices.map((selectedInvoice) => selectedInvoice.invoice.id);
        const body = { Invoices: invoiceIds };
        return this.delete(`${this.endpoint}consumers/:consumer/invoices`, [['consumer', consumer]], {
            data: body,
            headers: { 'X-consumer': consumer },
        });
    }
}
