import { Component, Prop, Watch } from 'vue-property-decorator';
import { consumerHelper, sourcesService, consumerService, pathService, dictionaryHelper } from '@/main';
import Vue from 'vue';
import moment from 'moment';
import PageRender from '@/models/PageRender';
import ImportedInvoice from '@/models/ImportedInvoice';
import { BModal } from 'bootstrap-vue';
import { GridExpandChangeEvent, GridSelectionChangeEvent, GridHeaderSelectionChangeEvent } from '@progress/kendo-vue-grid';
import invoiceDetails from '@/components/invoice/invoice-detail.vue';
import Source from '@/models/Source';
import to from 'await-to-js';
import { InvoiceType } from '@/models/InvoiceType';
import Path from '@/models/Path';
import { orderBy } from '@progress/kendo-data-query';
import { consumerModule } from '@/store/Consumer';
import { Invoice } from '@/models/Invoice';

@Component
export default class InvoiceGridComponent extends PageRender {
    @Prop({ default: () => [] }) public dataItems: ImportedInvoice[];
    public invoices: ImportedInvoice[] = [];
    public allInvoices: ImportedInvoice[] = [];

    public openInvoiceModal: ImportedInvoice = null;

    public $refs!: { successModal: BModal };

    public currentDate: string = moment().format('YYYY-MM');

    public invoiceNumber: string = '';
    public isReloadingGrid: boolean = false;

    public filterMonth: string = null;
    public onlyFailures: boolean = false;
    public textSearch: string = null;

    public cellTemplate: any = invoiceDetails;
    public expandedItems: any[];
    public selectedProviderId: string = null;
    public direction: number = 1;
    public selectedAction: string = null;
    public selectedProviderActionId: string = null;
    public forceImportToProvider: boolean = false;

    public sources: Source[] = [];

    public currentPath: Path = null;

    public skip: number = 0;
    public take: number = 50;

    public invoicesColumns = [
        { field: 'selected', width: 60, locked: true, className: 'bg-white', filterable: false },
        { field: 'invoice.invoiceNumber', title: 'Invoice', cell: this.renderNumberAndDescription },
        { field: 'invoice.customer.name', title: 'Customer', width: 200 },
        { field: 'invoice.invoiceDate', title: 'Invoice date', cell: this.renderInvoiceDate, width: 130 },
        { field: 'invoice.bookDate', title: 'Book date', cell: this.renderBookDate, width: 130 },
        { cell: this.renderProvider, title: 'Source', width: 240 },
        { title: 'Payment status', cell: this.renderPaymentStatus, width: 160 },
        { title: 'Status', cell: this.renderStatus, width: 80 },
    ];

    @Watch('filterMonth')
    public onDateFilterChange(val) {
        this.filterInvoices();
    }

    @Watch('onlyFailures')
    public onFailureFilterChange(val) {
        this.filterInvoices();
    }

    @Watch('textSearch')
    public onTextFilterChange(val, oldVal) {
        if ((val && val.length > 2) || (oldVal && oldVal.length > val.length)) {
            this.filterInvoices();
        }
    }

    public async created() {
        this.sources = await this.loadSources();

        const pathId = this.$route.params.id;
        this.currentPath = await pathService.getPath(pathId);
    }

    public async mounted() {
        this.dataItems.forEach((invoice) => {
            const map = this.getProviderMap(invoice);

            const triedProviders = Object.keys(map).length;
            const successProviders = Object.values(map).filter((val) => {
                return val.success >= 1;
            }).length;

            invoice.successProviders = successProviders;
            invoice.triedProviders = triedProviders;
        });

        this.allInvoices = this.invoices = orderBy(this.dataItems, [{ field: 'created', dir: 'desc' }]);

        this.filterInvoices();

        this.isReloadingGrid = false;
    }

    public async loadSources(): Promise<Source[]> {
        return await sourcesService.getSources();
    }

    public getTotal() {
        return this.invoices ? this.invoices.length : 0;
    }

    public pageChangeHandler(event) {
        this.skip = event.page.skip;
        this.take = event.page.take;
    }

    public renderCustomerEdit(h, _, row: { dataItem: ImportedInvoice }) {
        return h('grid-edit-property');
    }

    public renderNumberAndDescription(h, _, row: { dataItem: ImportedInvoice }) {
        return h('td', [
            `   ${row.dataItem.invoice.reference ? ' ' + row.dataItem.invoice.reference : ''}
                ${row.dataItem.invoice.invoiceNumber ? row.dataItem.invoice.invoiceNumber : ''}
                ${row.dataItem.invoice.invoiceDescription ? ' - ' + row.dataItem.invoice.invoiceDescription : ''}`,
        ]);
    }

    public rowClicked(e) {
        this.$emit('rowclick', e);
    }

    public renderPaymentStatus(h: any, a, row) {
        return h('td', dictionaryHelper.get('PaymentStatus')[row.dataItem.invoice.paymentStatus]);
    }

    public renderProvider(h: any, _, row: { dataItem: ImportedInvoice }) {
        const route = this.$router.resolve({
            name: 'provider',
            params: { providerId: row.dataItem.sourceProvider },
        });

        let props = { text: 'API', url: null };

        const foundConsumer = consumerHelper.consumers.find((consumer) => consumer.id === row.dataItem.consumer);

        if (!foundConsumer) {
            return h('td', 'Input API');
        }

        const foundProvider = foundConsumer.providers.find((prov) => prov.id === row.dataItem.sourceProvider);

        if (foundProvider) {
            props = { text: foundProvider.name, url: route.href };

            return h(Vue.component('grid-router-link'), { props });
        }

        return h('td', 'Input API');
    }

    public renderInvoiceDate(h: any, a, row) {
        return h('td', moment(row.dataItem.invoice.invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY'));
    }

    public renderBookDate(h: any, a, row: { dataItem: { invoice: Invoice } }) {
        const date = row.dataItem.invoice.bookDate
            ? moment(row.dataItem.invoice.bookDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
            : ' - ';
        return h('td', date);
    }

    public renderStatus(h: any, a: any, row) {
        const props = {
            action: () => this.showSuccessModal(row.dataItem),
            value: `${row.dataItem.successProviders} of ${row.dataItem.triedProviders} succeeded`,
            dataItem: row.dataItem,
        };

        return h('grid-function', { props });
    }

    public showSuccessModal(item) {
        this.openInvoiceModal = item;
        this.$refs.successModal.show();
    }

    public getProviderMap(invoice: ImportedInvoice) {
        return invoice.outputs
            .map((invc) => {
                return {
                    provider: invc.provider,
                    status: invc.status,
                };
            })
            .reduce((prev, cur) => {
                if (!prev[cur.provider]) {
                    prev[cur.provider] = {
                        success: 0,
                        failed: 0,
                    };
                }

                prev[cur.provider].success += cur.status === 2 ? 1 : 0;
                prev[cur.provider].failed += cur.status === 3 ? 1 : 0;

                return prev;
            }, []);
    }

    public getSuccessProvider(invoice: ImportedInvoice) {
        // const importedInvoice = new ImportedInvoice(invoice);
        const successProviders = [];
        invoice.outputs.forEach((finvoice) => {
            if (finvoice.status === 2) {
                const provider = consumerModule.currentProviders.find((x) => x.id === finvoice.provider);
                successProviders.push(provider);
            }
        });
        return successProviders;
    }

    public expandChange(event: GridExpandChangeEvent) {
        Vue.set(event.dataItem, event.target.$props.expandField, event.value);
    }

    public onSelectionChange(event: GridSelectionChangeEvent) {
        Vue.set(event.dataItem, 'inEdit', false);
        Vue.set(event.dataItem, 'selected', !event.dataItem.selected);
    }

    public onHeaderSelectionChange(event: GridHeaderSelectionChangeEvent) {
        const checked = event.event.target.checked;
        Vue.set(
            this,
            'invoices',
            this.invoices.map((item) => ({ ...item, selected: checked })),
        );
    }

    get selectedInvoices() {
        return this.invoices.filter((x) => x.selected === true);
    }

    public async deleteInvoices() {
        this.showPending('Deleting selected invoice(s)..');
        const failedDeletes = [];

        for (const invoice of this.selectedInvoices) {
            const [err] = await to(pathService.deleteInvoice(invoice.invoice.id, this.currentPath.id));
            if (err) {
                failedDeletes.push(invoice.invoice.id);
            }
        }

        if (failedDeletes.length > 0) {
            this.clearAndShowError(`Failed to delete following invoices: <br/><br/> Ids: ${this.selectedInvoices.join(', ')}`);
        } else {
            this.clearNotifications();
        }

        await this.reloadInvoices();
    }

    public getData() {
        return this.invoices.slice(this.skip, this.take + this.skip);
    }

    public async reloadInvoices() {
        this.$emit('reload-invoices');

        this.invoices = [];
        this.isReloadingGrid = true;
    }

    public async loadInvoices(type: InvoiceType): Promise<ImportedInvoice[]> {
        const [err, response] = await to(consumerService.getInvoicesOfConsumer(this.selectedConsumer.id, type));
        if (err) {
            this.showError('Failed to retrieve invoices');
        }

        return (this.allInvoices = this.invoices = response.data);
    }

    public getGroupedOutputs() {
        const result = this.groupBy(this.openInvoiceModal.outputs, 'provider');
        return result;
    }

    public filterInvoices() {
        Vue.set(
            this,
            'allInvoices',
            this.allInvoices.map((item) => ({ ...item, expanded: false })),
        );

        let invoices = this.allInvoices;
        if (this.filterMonth) {
            invoices = invoices.filter((invoice) => moment(invoice.invoice.invoiceDate).month() === moment(this.filterMonth).month());
        }

        if (this.textSearch) {
            invoices = invoices.filter((invoice) => this.filterPropsOnText(invoice, this.textSearch));
        }

        if (this.onlyFailures) {
            invoices = invoices.filter((invoice) => invoice.triedProviders > invoice.successProviders && invoice.triedProviders > 0);
        }

        this.invoices = invoices;
        this.skip = 0;
    }

    public filterPropsOnText(invoice: ImportedInvoice, text: string) {
        if (
            invoice.invoice.customer &&
            invoice.invoice.customer.name &&
            invoice.invoice.customer.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        ) {
            return true;
        }

        if (invoice.invoice.invoiceNumber && invoice.invoice.invoiceNumber.toString().indexOf(text) > -1) {
            return true;
        }

        if (invoice.invoice.invoiceDescription && invoice.invoice.invoiceDescription.toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return true;
        }

        if (invoice.invoice.totalAmount && invoice.invoice.totalAmount.toString().indexOf(text) > -1) {
            return true;
        }

        if (invoice.invoice.invoiceLines) {
            for (let i = 0; i < invoice.invoice.invoiceLines.length; i++) {
                const line = invoice.invoice.invoiceLines[i];
                if (line.description && line.description.toLowerCase().indexOf(text.toLowerCase()) > -1) {
                    Vue.set(invoice, 'expanded', true);
                    return true;
                }

                if (line.project && line.project.name && line.project.name.toLowerCase().indexOf(text.toLowerCase()) > -1) {
                    Vue.set(invoice, 'expanded', true);
                    return true;
                }

                if (line.department && line.department.name && line.department.name.toLowerCase().indexOf(text.toLowerCase()) > -1) {
                    Vue.set(invoice, 'expanded', true);
                    return true;
                }

                if (line.price && line.price.toString().indexOf(text) > -1) {
                    Vue.set(invoice, 'expanded', true);
                    return true;
                }
            }
        }

        return false;
    }
}
