import Component from 'vue-class-component';
import Vue from 'vue';
import { navigationHelper } from '@/main';
import { Watch } from 'vue-property-decorator';

@Component
export default class TopBarBreadcrumb extends Vue {
    public scope = '';
    public section = '';

    @Watch('$route', { immediate: true, deep: true })
    public onRouteChanged(val: any, oldVal: any) {
        if (val && val.meta && val.meta.scope && val.meta.section) {
            this.scope = val.meta.scope;
            this.section = val.meta.section;
        }
    }
}
