import { BaseService } from './baseService';
import { AxiosHelper } from '../helpers/axiosHelper';
import Vue from 'vue';

export class HealthService extends BaseService {
    private endpoint = Vue.$env().MiddlewareAPIEndpoint;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public checkHealth(pathId: string, startDate: string, endDate: string, includeResolved: boolean) {
        const url = `${this.endpoint}paths/${pathId}/validate`;
        return this.post(url, { startDate, endDate, includeResolved });
    }

    public setInvocieAsResolved(pathId: string, invoiceReference: string, message: string) {
        const url = `${this.endpoint}paths/${pathId}/resolve`;
        return this.post(url, { invoiceReference, message });
    }
}
