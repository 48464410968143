import { IProviderConfiguration } from "../IProviderConfiguration";


export default class AccountViewOutApi implements IProviderConfiguration {

    private baseUri: string = 'https://www.accountview.net/ams/authorize.aspx';
    private scopes: string = 'readaccountviewdata updateaccountviewdata'

    public getApplicationName(): string {
        return 'accountview';
    }

    public isOAuth2Provider(): boolean {
        return true;
    }

    public getAuthorizeUri(clientId: string, redirectUri: string): string {
        return `${this.baseUri}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${this.scopes}&response_type=code`;
    }

    public needsExtraConnectionContext(): boolean {
        return true;
    }
}
