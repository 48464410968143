import Provider from './Provider';
import Action from './Action';
import Validator from './Validator';

export default class Source {
    public name: string;
    public inputProviders: Provider[];
    public outputProviders: Provider[];
    public actions: Action[];
    public validators: Validator[];

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }

    public getInterSectedProviders(providers: Provider[]) {
        const returnProviders = [];
        this.inputProviders.forEach((iProv) => {
            const foundProviders = providers.filter((cProv) => cProv.provider === iProv.name);
            if (foundProviders) {
                foundProviders.forEach((fProv) => {
                    returnProviders.push({ value: fProv.id, text: fProv.name });
                });
            }
        });

        this.outputProviders.forEach((oProv) => {
            const foundProviders = providers.filter((cProv) => cProv.provider === oProv.name);
            if (foundProviders) {
                foundProviders.forEach((fProv) => {
                    returnProviders.push({ value: fProv.id, text: fProv.name });
                });
            }
        });

        return returnProviders;
    }
}
