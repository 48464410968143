import { ecosystemHelper, loginHelper, teamHelper } from '@/main';
import { profileModule } from '@/store/profile';
import { Component } from 'vue-property-decorator';
import BaseModal from './BaseModal';

@Component
export default class EcosystemSwitchModalComponent extends BaseModal {
    public currentEcosystem: number = null;

    public optionListEcosystems() {
        return profileModule.brightProfile.ecosystemMemberships
            .filter((x) => x.id !== ecosystemHelper.currentEcosystem.id)
            .map((ship) => {
                return { value: ship.id, text: ship.name };
            });
    }

    public async switchToAnotherEcosystem(ecosystemId: number) {
        const profile = profileModule.brightProfile;
        const ecosystem = profile.ecosystemMemberships.find((x) => x.id === ecosystemId);

        let team = profile.teamMemberships
            .filter((x) => x.ecosystem === ecosystem.id)
            .find((t) => {
                if (profile.primaryTeam) {
                    return t.id === profile.primaryTeam;
                }
            });

        if (!team) {
            team = profile.teamMemberships.filter((x) => x.ecosystem === ecosystem.id)[0];
        }

        this.$refs.baseModal.hide();

        ecosystemHelper.setEcosystemByEcosystemId(ecosystem.id);
        teamHelper.setTeamByTeamId(team.id);

        this.currentEcosystem = null;

        return this.$router.push({
            name: 'sync-paths-team',
            params: {
                ecosystemId: ecosystem.id.toString(),
                ecosystemKey: ecosystem.key,
                teamId: team.id.toString(),
                teamKey: team.key,
            },
        });
    }
}
