import Vue from 'vue';
import '@progress/kendo-ui/js/kendo.dropdownlist.min.js';
import { Grid, GridToolbar, GridNoRecords } from '@progress/kendo-vue-grid';
import { DropdownsInstaller } from '@progress/kendo-dropdowns-vue-wrapper';
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper';
import { InputsInstaller } from '@progress/kendo-inputs-vue-wrapper';
import { UploadInstaller } from '@progress/kendo-upload-vue-wrapper';
import { ListViewInstaller } from '@progress/kendo-listview-vue-wrapper';
import { PopupsInstaller } from '@progress/kendo-popups-vue-wrapper';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-vue-intl';
import DatePicker from 'vue2-datepicker';

import GridActiosComponent from '@/components/grid/grid-actions.vue';
import GridButtonComponent from '@/components/grid/grid-button.vue';
import GridTasksComponent from '@/components/grid/grid-tasks.vue';
import GridPopoverComponent from '@/components/grid/grid-popover-element.vue';
import GridAmountComponent from '@/components/grid/grid-amount.vue';
import GridFunctionComponent from '@/components/grid/grid-function.vue';
import GridSelectComponent from '@/components/grid/grid-select.vue';
import GridNumericEditorComponent from '@/components/grid/grid-numeric-editor.vue';
import GridEditProperty from '@/components/grid/grid-numeric-editor.vue';
import GridHtmlComponent from '@/components/grid/grid-html.vue';

import MultiSelect from 'vue-multiselect';
import BootstrapVue from 'bootstrap-vue';

Vue.component('grid', Grid);
Vue.component('grid-toolbar', GridToolbar);
Vue.component('grid-norecords', GridNoRecords);

Vue.component('date-picker', DatePicker);

Vue.component('menu-component', require('@/components/navmenu/navmenu.vue').default);

Vue.component('top-bar', require('@/components/top-bar/bar.vue').default);
Vue.component('top-bar-breadcrumb', require('@/components/top-bar/breadcrumb.vue').default);
Vue.component('top-bar-scope', require('@/components/top-bar/scope.vue').default);

Vue.component('localization-provider', LocalizationProvider);
Vue.component('intl-provider', IntlProvider);
Vue.component('multiselect', MultiSelect);

Vue.component('navigation-bar', require('@/components/navigation/bar.vue').default);
Vue.component('navigation-team', require('@/components/navigation/team.vue').default);
Vue.component('navigation-breadcrumb', require('@/components/navigation/breadcrumb.vue').default);
Vue.component('navigation-profile', require('@/components/navigation/profile.vue').default);

Vue.component('grid-function', GridFunctionComponent);
Vue.component('grid-amount', GridAmountComponent);
Vue.component('grid-popover-element', GridPopoverComponent);
Vue.component('grid-tasks', GridTasksComponent);
Vue.component('grid-button', GridButtonComponent);
Vue.component('grid-actions', GridActiosComponent);
Vue.component('grid-select', GridSelectComponent);
Vue.component('grid-numeric-editor', GridNumericEditorComponent);
Vue.component('grid-edit-property', GridEditProperty);
Vue.component('grid-html', GridHtmlComponent);

Vue.component('grid-router-link', require('@/components/grid/grid-router-link.vue').default);
Vue.component('top-bar-breadcrumb', require('@/components/top-bar/breadcrumb.vue').default);
Vue.component('spinner', require('@/components/spinner/spinner.vue').default);
Vue.component('fixed-toolbar', require('@/components/fixed toolbar/fixed-toolbar.vue').default);
Vue.component('debug-component', require('@/components/debug-component/debug.vue').default);
Vue.component('days-in-week', require('@/components/date/days-in-week.vue').default);

Vue.component('string-filter', require('@/components/filters/string-filter.vue').default);
Vue.component('number-filter', require('@/components/filters/number-filter.vue').default);
Vue.component('date-filter', require('@/components/filters/date-filter.vue').default);
Vue.component('select-filter', require('@/components/filters/select-filter.vue').default);
Vue.component('time-picker', require('@/components/timepicker/TimePicker.vue').default);

Vue.component('invoice-detail', require('@/components/invoice/invoice-detail.vue').default);
Vue.component('invoice-grid', require('@/components/invoice/invoice-grid.vue').default);
Vue.component('consumer-selector', require('@/components/consumer/consumer-selector.vue').default);

Vue.component('path-info', require('@/components/path/info.vue').default);
Vue.component('path-steps', require('@/components/path/steps.vue').default);

Vue.component('ecosystem-switcher-modal', require('@/components/modals/ecosystem-switcher-modal.vue').default);

Vue.use(DropdownsInstaller);
Vue.use(GridInstaller);
Vue.use(InputsInstaller);
Vue.use(UploadInstaller);
Vue.use(ListViewInstaller);
// Vue.use(DateinputsInstaller);
Vue.use(PopupsInstaller);
Vue.use(BootstrapVue);
