import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Path from '@/models/Path';
import BasePagePath from '@/models/BasePagePath';
import Step from '@/models/Step';
import { consumerModule } from '@/store/Consumer';
import Provider from '@/models/Provider';
import { sourceModule } from '@/store/Source';
import { IConsumerAction } from '@/models/Interfaces';
import { BModal } from 'bootstrap-vue';
import { sourcesService } from '@/main';

@Component
export default class PathStepsComponent extends BasePagePath {
    @Prop() public path?: Path;
    @Prop() public editMode?: boolean;

    public settings: any[] = [];
    public currentActionSettings: any = null;
    public currentStep: Step = null;
    public $refs!: {
        actionSettings: BModal;
    };

    public addStep() {
        this.path.steps.push(new Step());
    }

    public removeStep(index) {
        this.path.steps.splice(index, 1);
    }

    public findProvider(id: string) {
        const foundProvider = consumerModule.currentProviders.find((p: Provider) => {
            return p.id === id;
        });

        if (!foundProvider) {
            return new Provider();
        }

        return foundProvider;
    }

    public resetAction(step: Step) {
        if (step.stepType === 1) {
            step.name = '';
        }
    }

    public getActionProviders(name) {
        const action = sourceModule.consumerActions().find((a: IConsumerAction) => {
            return a.name === name;
        });
        return action ? action.providers : [];
    }

    public get outputActions() {
        return sourceModule.consumerActions();
    }

    public hasSettings(step) {
        const action = sourceModule.consumerActions().find((x) => x.name === step.name);
        return action && action.settings.length;
    }

    public setupActionSettings(step) {
        const action = sourceModule.consumerActions().find((x) => x.name === step.name);
        this.currentActionSettings = action.settings;
        this.currentStep = step;
        this.settings = step.settings;
        this.$refs.actionSettings.show();
    }

    public saveStepSetting() {
        for (const property in this.settings) {
            if (this.settings.hasOwnProperty(property)) {
                this.currentStep.settings[property] = this.settings[property];
            }
        }

        this.$refs.actionSettings.hide();
    }
}
