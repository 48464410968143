import { IStep } from './Interfaces';
import PathSchedule from './PathSchedule';

export default class Path {
    public id: string = '';
    public input: string = '';
    public steps: IStep[] = [];
    public name: string = '';
    public description?: string;
    public owner?: string;
    public schedule: PathSchedule = new PathSchedule();
    public teamId: number;
    public lookBackDays: number = 0;
    public maxLookBackDate: any;
    public maxEndDate: any;

    constructor(obj: any = {}) {
        Object.assign(this, obj);

        if (!this.schedule) {
            this.schedule = new PathSchedule();
        }
    }
}
