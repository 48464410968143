import { IProviderConfiguration } from '../IProviderConfiguration';

export default class TeamleaderInApi implements IProviderConfiguration {

    public isOAuth2Provider(): boolean {
        return true;
    }

    public getApplicationName(): string {
        return 'teamleader';
    }

    public getAuthorizeUri(clientId: string, redirectUri: string): string {
        const authorizationEndpoint = 'https://focus.teamleader.eu/oauth2/authorize';
        return `${authorizationEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`;
    }

    public needsExtraConnectionContext(): boolean {
        return false;
    }
}
