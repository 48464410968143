import PageRender from './PageRender';
import { sourcesService } from '@/main';
import Source from './Source';
import moment from 'moment';
import ImportedInvoice from './ImportedInvoice';

export default class BasePage extends PageRender {
    public isLoading: boolean = true;
    public sources: Source[] = [];
    public direction: number = 1;

    public skip: number = 0;
    public take: number = 50;

    public calculateHeight() {
        return { height: `${window.innerHeight - 200}px` };
    }

    public async loadSources(): Promise<Source[]> {
        return await sourcesService.getSources();
    }

    public optionsListProviders(direction?: number) {
        const list = this.selectedConsumer.providers
            .filter((prov) => {
                const providers = (direction ? direction : this.direction) === 1 ? this.getInputProviders() : this.getOutputProviders();

                return providers.filter((provider) => provider.name === prov.provider).length > 0;
            })
            .map((provider) => {
                return { value: provider.id, text: provider.name };
            });

        list.unshift({ value: null, text: 'Select a provider' });

        return list;
    }

    public getInputProviders() {
        const list = [];
        this.sources.forEach((source) => {
            list.push(...source.inputProviders);
        });
        return list;
    }

    public getOutputProviders() {
        const list = [];
        this.sources.forEach((source) => {
            list.push(...source.outputProviders);
        });
        return list;
    }

    public renderBoolean(h, bool: boolean) {
        return h('td', [bool ? 'Yes' : 'No']);
    }

    public renderNumberAndDescription(h, a, row: { dataItem: ImportedInvoice }) {
        return h('td', [
            `${row.dataItem.invoice.invoiceNumber ? row.dataItem.invoice.invoiceNumber : ''}${
                row.dataItem.invoice.invoiceDescription ? ' ' + row.dataItem.invoice.invoiceDescription : ''
            }`,
        ]);
    }

    public renderInvoiceDate(h: any, a, row) {
        return h('td', moment(row.dataItem.invoice.invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY'));
    }
}
