import { BaseService } from './baseService';
import { AxiosHelper } from '../helpers/axiosHelper';
import Vue from 'vue';
import ImportedInvoice from '@/models/ImportedInvoice';
import { AxiosPromise } from 'axios';
import PathRunResult from '@/models/PathRunResult';
import Path from '@/models/Path';
import { guidHelper, dateHelper } from '@/main';
import { IStep } from '@/models/Interfaces';

export class PathService extends BaseService {
    private endpoint = Vue.$env().MiddlewareAPIEndpoint;
    private manageEndpoint = Vue.$env().MiddlewareManagementAPIEndpoint;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public getPaths(teamId?: any, includeDeleted: boolean = false): AxiosPromise<Path[]> {
        let url = `${this.manageEndpoint}paths?include_deleted=${includeDeleted}`;
        if (teamId) {
            url += `&teamId=${teamId}`;
        }
        return this.get<Path[]>(url);
    }

    public async getPath(pathId: string): Promise<Path> {
        const response = await this.get<Promise<Path>>(`${this.manageEndpoint}team/:team/paths/:pathId`, [['pathId', pathId]]);

        if (!response) {
            console.error('Failed to load person');
            return new Path();
        }

        return new Path(response.data);
    }

    public savePath(path: Path) {
        path.steps.forEach((step: IStep) => {
            step.id = step.id ? step.id : guidHelper.guid();
        });

        if (path.id) {
            return this.put(`${this.manageEndpoint}team/${path.teamId ? path.teamId : ':team'}/paths/${path.id}`, path);
        } else {
            path.id = guidHelper.guid();
            return this.post(`${this.manageEndpoint}team/${path.teamId ? path.teamId : ':team'}/paths`, path);
        }
    }

    public clonePath(path: Path) {
        return this.post(`${this.manageEndpoint}team/${path.teamId ? path.teamId : ':team'}/paths/${path.id}/clone`);
    }

    public deletePath(path: Path) {
        return this.delete(`${this.manageEndpoint}team/:team/paths/${path.id}`);
    }

    public walkPath(pathId: string, startDate: Date, endDate: Date, runOutputPhase: boolean = true): AxiosPromise<PathRunResult> {
        const pathStartDate = dateHelper.format(startDate);
        const pathEndDate = dateHelper.format(endDate);
        return this.post(`${this.endpoint}paths/:pathId`, { startDate: pathStartDate, endDate: pathEndDate, runOutputPhase }, [['pathId', pathId]]);
    }

    public async getRuns(pathId: string): Promise<PathRunResult[]> {
        const response = await this.get(`${this.manageEndpoint}paths/:pathId/runs`, [['pathId', pathId]]);

        return response ? response.data : null;
    }

    public async getInvoices(pathId: string) {
        const response = await this.get<ImportedInvoice[]>(`${this.manageEndpoint}team/:team/paths/:pathId/invoices`, [['pathId', pathId]]);

        return response ? response.data : null;
    }

    public deleteInvoice(invoiceId: string, pathId: string) {
        return this.delete(`${this.manageEndpoint}team/:team/paths/:pathId/invoices/:invoiceId`, [
            ['pathId', pathId],
            ['invoiceId', invoiceId],
        ]);
    }

    public updateInvoice(invoice: ImportedInvoice, pathId: string) {
        return this.put<ImportedInvoice[]>(`${this.manageEndpoint}team/:team/paths/:pathId/invoices/:invoiceId`, invoice, [
            ['pathId', pathId],
            ['invoiceId', invoice.invoice.id],
        ]);
    }
}
