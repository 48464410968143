import { IProviderConfiguration } from '@/models/Providers/IProviderConfiguration';
import ReeleezeeInApi from '@/models/Providers/Types/Reeleezee.In.Api';
import BrightInPersons from '@/models/Providers/Types/Bright.In.Persons';
import BrightOutFinance from '@/models/Providers/Types/Bright.Out.Finance';
import BrightInvoicesInInvoiceApi from '@/models/Providers/Types/BrightInvoices.In.InvoiceApi';
import BrightInvoicesOutInvoices from '@/models/Providers/Types/BrightInvoices.Out.Invoices';
import ReeleezeeOutApi from '@/models/Providers/Types/Reeleezee.Out.Api';
import StripeInApi from '@/models/Providers/Types/Stripe.In.Api';
import TeamleaderInApi from '@/models/Providers/Types/TeamLeader.In.Api';
import RecreapiInInvoiceService from '@/models/Providers/Types/Recreapi.In.InvoiceService';
import ExactOnlineInEntries from '@/models/Providers/Types/ExactOnline.In.Entries';
import Unit4OutCustomerInvoices from '@/models/Providers/Types/Unit4.Out.CustomerInvoices';
import DefaultProviderConfiguration from '@/models/Providers/Types/DefaultProviderConfiguration';
import AccountViewOutApi from '@/models/Providers/Types/AccountView.Out.Api';

export default class ProviderHelper {
    public getProviderTypeConfigurationObject(providerType: string): IProviderConfiguration {
        switch (providerType) {
            case 'Bright.In.Persons':
                return new BrightInPersons();
            case 'Bright.Out.Finance':
                return new BrightOutFinance();
            case 'BrightInvoices.In.InvoiceApi':
                return new BrightInvoicesInInvoiceApi();
            case 'BrightInvoices.Out.Invoices':
                return new BrightInvoicesOutInvoices();
            case 'Reeleezee.In.Api':
                return new ReeleezeeInApi();
            case 'Reeleezee.Out.Api':
                return new ReeleezeeOutApi();
            case 'Stripe.In.Api':
                return new StripeInApi();
            case 'TeamLeader.In.Api':
                return new TeamleaderInApi();
            case 'Recreapi.In.InvoiceService':
                return new RecreapiInInvoiceService();
            case 'ExactOnline.In.Entries':
                return new ExactOnlineInEntries();
            case 'Unit4.Out.CustomerInvoices':
                return new Unit4OutCustomerInvoices();
                case 'AccountView.Out.Api':
                    return new AccountViewOutApi();
            default:
                return new DefaultProviderConfiguration();
        }
    }
}
