import { dictionaryHelper, ecosystemHelper } from '@/main';
import Step from './Step';
import BasePage from './BasePage';
import { consumerModule } from '@/store/Consumer';
import { sourceModule } from '@/store/Source';
import Provider from './Provider';
import { profileModule } from '@/store/profile';

export default class BasePagePath extends BasePage {
    public getStepName(stepId) {
        const fStep = consumerModule.current.getStepByStepId(stepId);
        const stepName = fStep.name ? fStep.name : this.getStepType(fStep.stepType);
        return `<u>Execute action:</u> ${stepName} on <strong>${this.getProviderName(fStep.provider)}</strong>`;
    }

    public getStepType(type) {
        return dictionaryHelper.get('StepType')[type];
    }

    public get teamList() {
        const profile = profileModule.brightProfile;
        if (!profile || !profile.teamMemberships.filter((x) => x.ecosystem === ecosystemHelper.currentEcosystem.id)) {
            return [];
        }

        return profile.teamMemberships.filter((x) => x.ecosystem === ecosystemHelper.currentEcosystem.id);
    }

    public get inputProvidersList() {
        const providers = consumerModule.currentProviders.filter((provider: Provider) => {
            return !!sourceModule.inputProviders.find((inputProvider: Provider) => {
                return inputProvider.name === provider.provider;
            });
        });
        return providers;
    }

    public get outputProvidersList() {
        const providers = consumerModule.currentProviders.filter((provider: Provider) => {
            return !!sourceModule.outputProviders.find((outputProvider: Provider) => {
                return outputProvider.name === provider.provider;
            });
        });
        return providers;
    }
}
