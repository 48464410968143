var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.isLoading)?_c('prd-navigation',[_c('prd-navigation-global',[_c('div',{staticClass:"prd-logo prd-logo-sm p-2",on:{"click":_vm.toggleNavigationContainer}},[_c('img',{staticStyle:{"width":"1.5rem"},attrs:{"src":"/logo.png","alt":"Middleware"}})]),(_vm.showIcons())?[_c('prd-navigation-icon',{attrs:{"id":"sync-base","variant":"warning","route":"sync-base","params":{
                    ecosystemId: _vm.$root.ecosystemHelper.currentEcosystem.id,
                    ecosystemKey: _vm.$root.ecosystemHelper.currentEcosystem.key,
                    teamId: _vm.$root.teamHelper.currentTeam.id,
                    teamKey: _vm.$root.teamHelper.currentTeam.key,
                },"icon":"target"}}),_c('prd-navigation-icon',{attrs:{"id":"settings-base","variant":"primary","route":"providers","params":{
                    ecosystemId: _vm.$root.ecosystemHelper.currentEcosystem.id,
                    ecosystemKey: _vm.$root.ecosystemHelper.currentEcosystem.key,
                },"icon":"settings"}}),_c('prd-tooltip',{attrs:{"target":"sync-base"}},[_vm._v("Sync")]),_c('prd-tooltip',{attrs:{"target":"settings-base"}},[_vm._v("Settings")])]:_vm._e()],2),_c('prd-navigation-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNavigationContainer),expression:"showNavigationContainer"}],attrs:{"variant":"light"}},[_c('div',{staticClass:"navigation-scope"},[_c('navigation-team')],1),(_vm.isActive('home'))?[_c('prd-navigation-item',{attrs:{"route":"home","icon":"home","label":"Home"}})]:_vm._e(),(_vm.isActive('sync-base'))?[_c('prd-navigation-item',{attrs:{"route":"sync-paths","icon":"user","global":"sync-base","label":"All ecosystem paths"}}),_vm._l((_vm.pathLinks),function(pathLink){return (!_vm.isEcoScope)?_c('prd-navigation-item',{key:pathLink.id,class:{
                    'status-success': pathLink.status == 1,
                    'status-fail': pathLink.status == 2,
                    'status-warning': pathLink.status == 3,
                    'line-through': !pathLink.schedule.enabled
                },attrs:{"route":"sync-path-detail","params":{
                    id: pathLink.id,
                    ecosystemId: _vm.$root.ecosystemHelper.currentEcosystem.id,
                    ecosystemKey: _vm.$root.ecosystemHelper.currentEcosystem.key,
                    teamId: _vm.$root.teamHelper.currentTeam.id,
                    teamKey: _vm.$root.teamHelper.currentTeam.key,
                },"active":_vm.pathIsActive(pathLink),"override-active":true,"icon":"target","global":"sync-base","label":pathLink.name}}):_vm._e()})]:_vm._e(),(_vm.isActive('setting-base'))?[_c('prd-navigation-item',{attrs:{"route":"providers","icon":"user","label":"Providers"}})]:_vm._e()],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }