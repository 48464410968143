import Vue from 'vue';
import { BaseService } from './baseService';
import { to } from 'await-to-js';

export default class BrightService extends BaseService {
    private endpoint = `${Vue.$env().BrightDirectoryEndpoint}`;

    public async getStructure(ecosystem: number) {
        const montString = `0${new Date().getMonth() + 1}`.slice(-2);

        const [err, response] = await to(
            this.get(`${this.endpoint}ecosystems/:ecosystem`, [['ecosystem', ecosystem]], {
                headers: {
                    'x-period': `${new Date().getFullYear()}-${montString}`,
                },
            }),
        );

        if (err) {
            // this.clearAndShowError(`Failed to load ecosystem`, err);
            return { teams: [{ groups: [] }] };
        }

        return response.data;
    }
}
