import Vue from 'vue';
import App from './App.vue';
import router from './router';
import EnvPlugin from './plugin/EnvPlugin';

import 'vue2-datepicker/index.css';
import SharedUI from '@prd/shared-ui';
import '@progress/kendo-ui/js/kendo.core.min.js';
import '@progress/kendo-ui/js/cultures/kendo.culture.nl-NL.js';
import './components/index';
import { loadMessages } from '@progress/kendo-vue-intl';
import VCalendar from 'v-calendar';
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';
import moment from 'moment';
import VueFilterDateFormat from 'vue-filter-date-format';

moment.locale('nl');
Vue.use(SharedUI);

import store from './store';

// Helpers
import { EventBus } from './events/EventBus';
import { AxiosHelper } from './helpers/axiosHelper';
import { ErrorHelper } from './helpers/errorHelper';
import { LoginHelper } from './helpers/loginHelper';
import { PeriodFilterHelper } from './helpers/periodFilterHelper';
import NavigationHelper from './helpers/navigationHelper';
import DictionaryHelper from './helpers/dictionaryHelper';
import DateHelper from './helpers/dateHelper';
import { RoleHelper } from './helpers/roleHelper';
import { EcosystemHelper } from './helpers/ecosystemHelper';
import { ConsumerHelper } from './helpers/consumerHelper';
import { GuidHelper } from './helpers/guidHelper';

// Services
import { LoginService } from './services/loginService';
import { MeService } from './services/meService';
import { ConsumerService } from './services/consumerService';
import { SyncService } from './services/syncService';
import { SourcesService } from './services/sourcesService';
import { ActionService } from './services/actionService';
import { HealthService } from './services/heatlhService';
import { PathService } from './services/pathService';
import { InvoiceService } from './services/invoicesService';
import BrightService from './services/brightService';
import ProviderHelper from './helpers/providerHelper';
import { TeamHelper } from './helpers/teamHelper';
import { TeamService } from './services/teamService';
import { ProviderService } from './services/providerService';
import CountryService from './services/countryService';

Vue.use(EnvPlugin);
Vue.use(VueFilterDateFormat);

const nlMessages = {
    datepicker: {
        toggleCalendar: 'Alternar calendario',
    },
    calendar: {
        today: 'Vandaag',
    },
    dateinput: {
        increment: 'Incrementar valor',
        decrement: 'Disminuir valor',
    },
    numerictextbox: {
        increment: 'Incrementar valor',
        decrement: 'Disminuir valor',
    },
    grid: {
        groupPanelEmpty: 'Arrastre el t�tulo de una columna y su�ltelo aqu� para agrupar por ese criterio',
        noRecords: 'Geen data beschikbaar',
        pagerFirstPage: 'Ir a la primera p�gina',
        pagerPreviousPage: 'Ir a la p�gina anterior',
        pagerNextPage: 'Ir a la p�gina siguiente',
        pagerLastPage: 'Ir a la �ltima p�gina',
        pagerPage: 'P�gina',
        pagerOf: 'de',
        pagerItems: '�tems',
        pagerInfo: '{0} - {1} de {2} �tems',
        pagerItemsPerPage: '�tems por p�gina',
        filterEqOperator: 'Es igual a',
        filterNotEqOperator: 'No es igual a',
        filterIsNullOperator: 'Es nulo',
        filterIsNotNullOperator: 'No es nulo',
        filterIsEmptyOperator: 'Est� vac�o',
        filterIsNotEmptyOperator: 'No est� vac�o',
        filterStartsWithOperator: 'Comienza con',
        filterContainsOperator: 'Contiene',
        filterNotContainsOperator: 'No contiene',
        filterEndsWithOperator: 'Termina en',
        filterGteOperator: 'Es mayor o igual que',
        filterGtOperator: 'Es mayor que',
        filterLteOperator: 'Es menor o igual que',
        filterLtOperator: 'Es menor o igual que',
        filterIsTrue: 'S�',
        filterIsFalse: 'No',
        filterBooleanAll: '(Todas)',
        filterAfterOrEqualOperator: 'Es posterior o igual a',
        filterAfterOperator: 'Es posterior',
        filterBeforeOperator: 'Es anterior',
        filterBeforeOrEqualOperator: 'Es anterior o igual a',
        filterFilterButton: 'Filtrar',
        filterClearButton: 'Verwijder filters',
        filterAndLogic: 'En',
        filterOrLogic: 'Of',
    },
};
loadMessages(nlMessages, 'nl-NL');
kendo.culture('en-US');

const aDictionaryHelper = new DictionaryHelper();
aDictionaryHelper.setActiveLanguage(kendo.culture().name);

Vue.use(Notifications);
Vue.use(VCalendar, {
    firstDayOfWeek: 2,
    locale: 'nl',
    masks: { L: 'DD-MM-YYYY' },
    datePicker: { popover: { visibility: 'click' } },
});
Vue.use(Vuelidate);

export const $router = router;
export const eventBus = new EventBus();
export const axiosHelper = new AxiosHelper({}, {});
export const loginService = new LoginService();

export const loginHelper = new LoginHelper(loginService);
export const meService = new MeService(axiosHelper);
export const errorHelper = new ErrorHelper(router);
export const periodFilterHelper = new PeriodFilterHelper();
export const navigationHelper = new NavigationHelper();
export const dictionaryHelper = aDictionaryHelper;
export const dateHelper = new DateHelper();
export const roleHelper = new RoleHelper();
export const ecosystemHelper = new EcosystemHelper();
export const teamHelper = new TeamHelper();
export const consumerHelper = new ConsumerHelper();
export const guidHelper = new GuidHelper();
export const providerHelper = new ProviderHelper();

export const consumerService = new ConsumerService(axiosHelper);
export const syncService = new SyncService(axiosHelper);
export const sourcesService = new SourcesService(axiosHelper);
export const actionService = new ActionService(axiosHelper);
export const healthService = new HealthService(axiosHelper);
export const pathService = new PathService(axiosHelper);
export const brightService = new BrightService(axiosHelper);
export const invoiceService = new InvoiceService(axiosHelper);
export const teamService = new TeamService(axiosHelper);
export const providerService = new ProviderService(axiosHelper);
export const countryService = new CountryService(axiosHelper);

Vue.config.productionTip = false;

Vue.filter('friendlyDate', (value) => {
    if (!value) {
        return '';
    }
    return moment(value).format('dddd D MMMM');
});

Vue.filter('friendlyDateTime', (value) => {
    if (!value) {
        return '';
    }
    return moment(value).format('dddd D MMMM HH:mm:ss');
});

Vue.filter('formatDateTime', (value) => {
    if (!value) {
        return '';
    }
    return moment(value).format('DD-MM-YYYY HH:mm:ss');
});

Vue.filter('formatDate', (value) => {
    if (!value) {
        return '';
    }
    return moment(value).format('DD-MM-YYYY');
});

Vue.filter('formatPrice', (value) => {
    return kendo.toString(value, 'c');
});

axiosHelper.setup();
new Vue({
    router,
    store,
    data: {
        environment: (window as any).environment,
        isLoading: true,
        roleHelper,
        teamHelper,
        consumerHelper,
        ecosystemHelper,
        loginHelper,
        navigationHelper,
    },
    render: (h) => h(App),
}).$mount('#app');
