export default class Step {
    public id: string = '';
    public name: string = '';
    public stepType: number = 0;
    public provider: string = null;
    public settings: object = {};

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
