import { IProviderConfiguration } from '../IProviderConfiguration';

export default class ReeleezeeOutApi implements IProviderConfiguration {

    public getApplicationName(): string {
        throw new Error('Method not implemented.');
    }

    public isOAuth2Provider(): boolean {
        return false;
    }

    public getAuthorizeUri(clientId: string, redirectUri: string): string {
        throw new Error('Method not implemented.');
    }

    public needsExtraConnectionContext(): boolean {
        return false;
    }
}
