import Component from 'vue-class-component';
import Vue from 'vue';
import { ecosystemHelper, navigationHelper, teamHelper } from '@/main';

@Component
export default class TopBarBreadcrumb extends Vue {
    public getCurrentTeamName() {
        const team = teamHelper.currentTeam;
        return team ? team.name : '';
    }

    public getGroupKey() {
        return this.$route.params.groupKey;
    }

    public getRouteObject() {
        return {
            name: `${navigationHelper.scope}-${navigationHelper.section}`,
            params: {
                ecosystemId: this.$route.params.ecosystemId,
                ecosystemKey: this.$route.params.ecosystemKey,
                teamId: this.$route.params.teamId,
                teamKey: this.$route.params.teamKey,
            },
        };
    }

    public toggleNavMenu() {
        navigationHelper.toggleNavigation();
    }

    public paths() {
        return this.$router.push({
            name: 'sync-paths-team',
            params: {
                ecosystemId: ecosystemHelper.currentEcosystem.id.toString(),
                ecosystemKey: ecosystemHelper.currentEcosystem.key,
                teamId: teamHelper.currentTeam.id.toString(),
                teamKey: teamHelper.currentTeam.key,
            },
        });
    }

    public settings() {
        return this.$router.push({ name: 'settings-base' });
    }

    public get variant() {
        if (this.$route.meta.app === 'crm') {
            return 'success';
        }
        if (this.$route.meta.app === 'invoices') {
            return 'warning';
        }
        if (this.$route.meta.app === 'purchases') {
            return 'primary';
        }
        return 'light';
    }

    public get scope() {
        if (this.$route.meta.app === 'crm') {
            return 'CRM_APP';
        }
        if (this.$route.meta.app === 'invoices') {
            return 'INVOICES_APP';
        }
        if (this.$route.meta.app === 'purchases') {
            return 'PURCHASES_APP';
        }
        return 'SETTINGS';
    }

    public get breadcrumb() {
        return this.$route.meta && this.$route.meta.breadcrumb ? this.$route.meta.breadcrumb : {};
    }

    public get breadcrumbItems() {
        const items = [];
        if (this.breadcrumb && this.breadcrumb.items) {
            this.breadcrumb.items.forEach((routeName) => {
                const resolved = this.$router.resolve({ name: routeName, params: this.$route.params });
                if (resolved && resolved.route.meta && resolved.route.meta.breadcrumb) {
                    items.push({
                        text: resolved.route.meta.breadcrumb.text,
                        route: resolved.route,
                        path: resolved.href,
                    });
                }
            });
        }

        return items;
    }

    public get splitHref() {
        if (!this.breadcrumb.split) {
            return '';
        }

        const route = this.$router.resolve({
            name: this.breadcrumb.split,
            params: {
                ecosystemId: this.$route.params.ecosystemId,
                ecosystemKey: this.$route.params.ecosystemKey,
                teamId: this.$route.params.teamId,
                teamKey: this.$route.params.teamKey,
            },
        });
        return route.href;
    }

    public goTo(resolvedRoute) {
        return this.$router.push(resolvedRoute);
    }
}
