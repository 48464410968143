import { IProviderConfiguration } from '../IProviderConfiguration';
import { IProviderField } from '../ProviderFields/IProviderField';
import TextField from '../ProviderFields/TextField';

export default class ExactOnlineInEntries implements IProviderConfiguration {

    public getApplicationName(): string {
        return 'exactonline';
    }

    public isOAuth2Provider(): boolean {
        return true;
    }

    public getAuthorizeUri(clientId: string, redirectUri: string): string {
        return `https://start.exactonline.nl/api/oauth2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`;
    }

    public needsExtraConnectionContext(): boolean {
        return false;
    }
}


