import { BaseService } from './baseService';
import { AxiosHelper } from '../helpers/axiosHelper';
import Vue from 'vue';
import ImportedInvoice from '@/models/ImportedInvoice';

export class SyncService extends BaseService {
    private endpoint = Vue.$env().MiddlewareAPIEndpoint;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public ImportToMiddleware(start, end, provider, consumer: string) {
        return this.post(`${this.endpoint}input`, { startDate: start, endDate: end, Provider: provider }, null, {
            headers: { 'X-consumer': consumer },
        });
    }

    public ExportToMiddleware(selectedInvoices: ImportedInvoice[], provider: string, consumer: string, forceImportToProvider: boolean = false) {
        let data = null;
        if (forceImportToProvider) {
            data = {
                Invoices: selectedInvoices.map((selectedInvoice) => selectedInvoice.invoice.id),
                Provider: provider,
                ForceImportToProvider: true,
            };
        } else {
            data = { Invoices: selectedInvoices.map((selectedInvoice) => selectedInvoice.invoice.id), Provider: provider };
        }

        return this.post<ImportedInvoice[]>(
            `${this.endpoint}output`,
            data,
            null,
            { headers: { 'X-consumer': consumer } },
        );
    }
}
