export default class Enviroment {
    public name: string = '';
    public IdentityEndpoint: string = '';
    public BrightDirectoryEndpoint: string = '';
    public MiddlewareAPIEndpoint: string = '';
    public MiddlewareManagementAPIEndpoint: string = '';
    public PortalUrl: string = '';
    public ClientId: string = '';

    public enableDebug: boolean = false;

    constructor(object: any) {
        Object.assign(this, object);
    }
}
