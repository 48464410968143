import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class TimePicker extends Vue {
    @Prop({ type: Number, default: 0 }) public hours: number;
    @Prop({ type: Number, default: 0 }) public minutes: number;
    public hourOptions: Array<{ text: string, value: number}> = [];
    public minuteOptions: Array<{ text: string, value: number }> = [];

    public mounted() {
        for (let i = 0; i <= 23; i++) {
            this.hourOptions.push({
                text: i.toString().padStart(2, '0'),
                value: i,
            });
        }

        for (let i = 0; i < 12; i++) {
            const minutes = i * 5;
            this.minuteOptions.push({
                text: minutes.toString().padStart(2, '0'),
                value: minutes,
            });
        }
    }
}
