import Consumer from '@/models/Consumer';
import to from 'await-to-js';
import { consumerService } from '@/main';

export class ConsumerHelper {
    // tslint:disable: variable-name
    private _selectedConsumer: Consumer = new Consumer();
    private _consumers: Consumer[] = [];

    get selectedConsumer(): Consumer {
        if (!this._selectedConsumer.id || this._selectedConsumer.id === '') {
            Object.assign(this._selectedConsumer, this._consumers[0]);
        }

        return this._selectedConsumer;
    }

    set selectedConsumer(consumer: Consumer) {
        this._selectedConsumer = new Consumer(consumer);
    }

    get consumers(): Consumer[] {
        return this._consumers;
    }

    set consumer(consumers: Consumer[]) {
        this._consumers = consumers;
    }

    public async Init() {
        await this.loadConsumers();
    }

    public getSelectedConsumerId() {
        return this.selectedConsumer.id;
    }

    public async loadConsumers() {
        const [err, response] = await to(consumerService.getConsumers());
        if (err) {
            console.error('Failed to retrieve consumers');
        }
        this._consumers = response.data;
    }
}
