import to from 'await-to-js';
import { consumerService, pathService, providerService, teamHelper } from '@/main';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import store from '../store';
import Consumer from '@/models/Consumer';
import Path from '@/models/Path';
import { messageModule } from './Messages';
import { $router } from '@/router';
import Provider from '@/models/Provider';

@Module
class ConsumerModule extends VuexModule {
    private consumers: Consumer[] = [];
    private active: Consumer = new Consumer();
    private failed: boolean = false;

    private paths: Path[] = [];
    private allPaths: Path[] = [];
    private providers: Provider[] = [];

    public get currentPaths() {
        return this.paths;
    }

    public get ecosystemPaths() {
        return this.allPaths;
    }

    public get currentProviders() {
        return this.providers;
    }

    public get all() {
        return this.consumers;
    }

    public get current() {
        return this.active;
    }

    public get first(): Consumer {
        return this.consumers && this.consumers.length > 0 ? this.consumers[0] : null;
    }

    public get find() {
        return (consumerGuid: string) => {
            const consumer = this.consumers.find((c: Consumer) => {
                return c.id === consumerGuid;
            });
            return new Consumer(consumer);
        };
    }

    public get consumerPath() {
        return (consumerGuid: string, pathGuid: string) => {
            const consumer = this.find(consumerGuid);
            if (!consumer) {
                return null;
            }

            return consumer.paths.find((path: Path) => {
                return path.id === pathGuid;
            });
        };
    }

    @Action
    public async fetch() {
        const [err, response] = await to(consumerService.getConsumers());
        if (err) {
            this.fail();
        }

        const items = response && response.data ? response.data : [];
        this.set(items);
    }

    @Action
    public async fetchIfNeeded() {
        if (this.consumers.length === 0) {
            return await this.fetch();
        }
    }

    @Action
    public async savePath(args: { path: Path; consumer: Consumer }) {
        const [err, response] = await to(pathService.savePath(args.path));
        if (err) {
            messageModule.addError('Failed to create path');
            return false;
        } else {
            messageModule.addSucces('Succesfully created path');
            this.ADD_PATH({ consumerGuid: args.consumer.id, path: args.path });
            return true;
        }
    }

    @Action
    public async setCurrentConsumer(consumer: Consumer) {
        this.SET_CURRENT(consumer);
        await this.fetchPaths();
    }

    @Action
    public async fetchPaths() {
        const [err, response] = await to(pathService.getPaths(teamHelper.currentTeam.id));
        if (err) {
            this.fail();
        }

        const items = response && response.data ? response.data : [];
        this.SET_PATHS(items);
    }

    @Action
    public async fetchAllPaths() {
        const [err, response] = await to(pathService.getPaths());
        if (err) {
            this.fail();
        }

        const items = response && response.data ? response.data : [];
        this.SET_ALL_PATHS(items);
    }

    @Action
    public async fetchProviders() {
        const [err, prodivers] = await to(providerService.getProviders());
        if (err) {
            this.fail();
        }

        const items = prodivers ? prodivers : [];
        this.SET_PROVIDERS(items);
    }

    @Mutation
    private fail() {
        this.consumers = [];
        this.failed = true;
    }

    @Mutation
    private set(payload) {
        this.consumers = payload;
        this.failed = false;
    }

    @Mutation
    private SET_CURRENT(consumer: Consumer) {
        this.active = new Consumer(consumer);
    }

    @Mutation
    private SET_PATHS(payload) {
        this.paths = payload;
    }

    @Mutation
    private SET_ALL_PATHS(payload) {
        this.allPaths = payload;
    }

    @Mutation
    private SET_PROVIDERS(payload) {
        this.providers = payload;
    }

    @Mutation
    private ADD_PATH(args: { consumerGuid: string; path: Path }) {
        const consumer = this.consumers.find((c: Consumer) => {
            return c.id === args.consumerGuid;
        });
        consumer.paths.push(args.path);
    }
}

export const consumerModule = new ConsumerModule({ store, name: 'consumer' });
