import { BaseService } from './baseService';
import { AxiosHelper } from '../helpers/axiosHelper';
import Vue from 'vue';
import ImportedInvoice from '@/models/ImportedInvoice';

export class ActionService extends BaseService {
    private endpoint = Vue.$env().MiddlewareAPIEndpoint;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public executeAction(selectedInvoices: ImportedInvoice[], provider: string, action: string, consumer: string) {
        return this.post(`${this.endpoint}actions`, { Invoices: selectedInvoices.map((selectedInvoice) => selectedInvoice.invoice.id), Provider: provider, ActionName: action }, null, {
            headers: { 'X-consumer': consumer },
        });
    }
}
