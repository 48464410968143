import { BaseService } from './baseService';
import { AxiosHelper } from '../helpers/axiosHelper';
import { InvoiceType } from '@/models/InvoiceType';
import to from 'await-to-js';
import { SalesInvoice as Invoice, SalesInvoice } from '@/models/Invoice';
import { ApiResponse } from '@/models/BrightInvoices/Models';

export class InvoiceService extends BaseService {
    private endpoint = 'https://t-prd-lib-invoice-api.azurewebsites.net/';

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getPurchaseInvoices(legalEntity: string): Promise<ApiResponse<Invoice>> {
        const [error, response] = await to(
            this.get<ApiResponse<Invoice>>(`${this.endpoint}:legalEntity/purchase-invoices`, [['legalEntity', legalEntity]]),
        );
        return response.data;
    }

    public async getSalesInvoices(legalEntity: string, type: InvoiceType = InvoiceType.Income): Promise<ApiResponse<Invoice>> {
        const [error, response] = await to(
            this.get<ApiResponse<Invoice>>(`${this.endpoint}:legalEntity/sales-invoices`, [['legalEntity', legalEntity]]),
        );
        return response.data;
    }

    public updateInvoice(invoice: Invoice, siteId: any): Promise<any> {
        throw new Error('Method not implemented.');
    }

    public async bookInvoice(invoice: any, legalEntity: string, isSales: boolean): Promise<any> {
        if (isSales) {
            const [err, responseSale] = await to(
                this.post(`${this.endpoint}:legalEntity/sales-invoices/:invoiceId/book`, { invoice }, [
                    ['legalEntity', legalEntity],
                    ['invoiceId', invoice.invoiceId],
                ]),
            );
            if (err) {
                return console.error('Failed to book invoice');
            }
            return responseSale.data;
        }

        const [error, response] = await to(
            this.post(`${this.endpoint}:legalEntity/purchase-invoices/:invoiceId/book`, { invoice }, [
                ['legalEntity', legalEntity],
                ['invoiceId', invoice.invoiceId],
            ]),
        );
        if (error) {
            return console.error('Failed to book invoice');
        }
        return response.data;
    }

    public async createInvoice(invoice: Invoice, legalEntity: string, isSales: boolean): Promise<any> {
        if (isSales) {
            const [err, responseSale] = await to(
                this.post(`${this.endpoint}:legalEntity/sales-invoices`, { invoice }, [['legalEntity', legalEntity]]),
            );
            if (err) {
                return console.error('Failed to create invoice');
            }
            return responseSale.data;
        }

        const [error, response] = await to(this.post(`${this.endpoint}:legalEntity/purchase-invoices`, { invoice }, [['legalEntity', legalEntity]]));
        if (error) {
            return console.error('Failed to create invoice');
        }
        return response.data;
    }
}
