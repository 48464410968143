import { AxiosPromise } from 'axios';
import { Person } from '../models/Person';
import { AxiosHelper } from '../helpers/axiosHelper';
import { BaseService } from './baseService';
import Vue from 'vue';
import { teamHelper } from '@/main';
import to from 'await-to-js';

export class TeamService extends BaseService {
    private endpoint = `${Vue.$env().BrightDirectoryEndpoint}teams`;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getMembers(teamId?: number): Promise<Person[]> {
        if (!teamId) {
            teamId = teamHelper.currentTeam.id;
        }

        const [err, response] = await to(
            this.get<Person[]>(`${this.endpoint}/:team/members`, [['team', teamId]]),
        );
        if (err) {
            return [];
        }

        return response.data.map((x) => new Person(x));
    }
}
