import nl from '@/helpers/dictionaries/nl';
import en from '@/helpers/dictionaries/en';

export default class DictionaryHelper {
    public languages = {
        'nl-NL': nl,
        'en-US': en,
    };

    public dictionary: any = {};

    public setActiveLanguage(key) {
        if (!this.languages[key]) {
            console.error(`The dictionary '${key}' does not exist, make sure to add it in languages.`);
        }

        this.dictionary = this.languages[key];
    }

    public get(key: string) {
        if (!this.dictionary[key]) {
            return key;
        }

        return this.dictionary[key];
    }
}
