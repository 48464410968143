import Vue from 'vue';
import Router, { Route } from 'vue-router';
import VueRouter from 'vue-router';
import { navigationHelper, roleHelper, teamHelper, ecosystemHelper } from './main';
import Base from './pages/Base.vue';
import { profileModule } from './store/profile';

Vue.use(Router);

const routes = [
    {
        name: 'home',
        path: '/',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/home/home.vue'),
        meta: { menu: 'default-menu', section: 'home', scope: 'welcome', global: 'home' },
    },
    { name: 'error', path: '/error-page', component: () => import(/* webpackChunkName: "error" */ '@/pages/error/error.vue') },
    { name: 'oauth-signin', path: '/oauth-signin', component: () => import(/* webpackChunkName: "oauth" */ '@/pages/oauth/sign-in.vue') },
    { name: 'login', path: '/login', component: () => import(/* webpackChunkName: "login" */ '@/pages/account/login/login.vue') },
    {
        path: '/:ecosystemId(\\d+)-:ecosystemKey/',
        component: Base,
        children: [
            {
                name: 'sync-base',
                path: 'sync',
                meta: { scope: 'paths', section: 'Income', global: 'sync-base' },
                redirect: { name: 'sync-paths-team' },
            },
            {
                name: 'sync-invoices',
                path: 'sync/invoices',
                component: () => import(/* webpackChunkName: "finance" */ '@/pages/financial/invoices.vue'),
                meta: { scope: 'paths', section: 'Income', global: 'sync-base' },
            },
            {
                name: 'sync-paths',
                path: 'sync/paths',
                component: () => import(/* webpackChunkName: "paths" */ '@/pages/paths/paths.vue'),
                meta: { scope: 'paths', section: 'overview', global: 'sync-base' },
            },
            {
                name: 'sync-paths-team',
                path: ':teamId(\\d+)-:teamKey/sync/paths',
                component: () => import(/* webpackChunkName: "paths" */ '@/pages/paths/paths.vue'),
                meta: { scope: 'paths', section: 'overview', global: 'sync-base' },
            },
            {
                name: 'sync-path-new',
                path: ':teamId(\\d+)-:teamKey/sync/path/new',
                component: () => import(/* webpackChunkName: "paths" */ '@/pages/paths/edit.vue'),
                meta: { scope: 'paths', section: 'new', global: 'sync-base' },
            },
            {
                name: 'sync-path-detail',
                path: ':teamId(\\d+)-:teamKey/sync/path/:id',
                component: () => import(/* webpackChunkName: "paths" */ '@/pages/paths/path.vue'),
                meta: { scope: 'paths', section: 'path', global: 'sync-base' },
            },
            {
                name: 'sync-path-edit',
                path: ':teamId(\\d+)-:teamKey/sync/path/:id/edit',
                component: () => import(/* webpackChunkName: "paths" */ '@/pages/paths/edit.vue'),
                meta: { scope: 'paths', section: 'path', global: 'sync-base' },
            },
            {
                name: 'sync-path-invoice-detail',
                path: ':teamId(\\d+)-:teamKey/sync/path/:id/invoices/:invoiceId',
                component: () => import(/* webpackChunkName: "paths" */ '@/pages/paths/invoice.vue'),
                meta: { scope: 'paths', section: 'path', global: 'sync-base' },
            },
            // {
            //     name: 'settings',
            //     path: 'settings',
            //     component: () => import(/* webpackChunkName: "settings" */ '@/pages/settings/edit'),
            //     meta: { scope: 'settings', section: 'path', global: 'setting-base' },
            // },
            {
                name: 'providers',
                path: 'providers',
                component: () => import(/* webpackChunkName: "finance" */ '@/pages/settings/providers.vue'),
                meta: { scope: 'settings', section: 'Providers', global: 'setting-base' },
            },
            {
                name: 'provider',
                path: 'provider/:providerId',
                component: () => import(/* webpackChunkName: "finance" */ '@/pages/settings/provider.vue'),
                meta: { scope: 'settings', section: 'Provider - detail', global: 'setting-base' },
            },
        ],
    },
    {
        name: 'finacial-base',
        path: '/financial',
        component: () => import(/* webpackChunkName: "finance" */ '@/pages/financial/base.vue'),
        redirect: { name: 'invoices' },
        meta: { excludedRoles: [] },
        children: [
            // Financieel
            {
                name: 'expenses',
                path: '/expenses',
                component: () => import(/* webpackChunkName: "finance" */ '@/pages/financial/expenses.vue'),
                meta: { scope: 'financial', section: 'Expenses', global: 'financial-base' },
            },
            {
                name: 'consumers',
                path: '/consumers',
                component: () => import(/* webpackChunkName: "finance" */ '@/pages/financial/consumers.vue'),
                meta: { scope: 'financial', section: 'Consumers', global: 'financial-base' },
            },
            {
                name: 'consumer',
                path: '/consumers/:consumerId',
                component: () => import(/* webpackChunkName: "finance" */ '@/pages/financial/consumer.vue'),
                meta: { scope: 'financial', section: 'Consumer - detail', global: 'financial-base' },
            },
            {
                name: 'manage-invoices',
                path: '/consumers',
                component: () => import(/* webpackChunkName: "finance" */ '@/pages/financial/consumers.vue'),
                meta: { scope: 'financial', section: 'Consumers', global: 'financial-base' },
            },
            {
                name: 'health-check',
                path: '/health-check',
                component: () => import(/* webpackChunkName: "finance" */ '@/pages/financial/health-check.vue'),
                meta: { scope: 'financial', section: 'health-check', global: 'financial-base' },
            },
        ],
    },
];

const router = new VueRouter({ mode: 'history', routes });

const checkRoleAccess = (roles) => {
    let access = true;
    if (!roles) {
        return true;
    }

    roles.forEach((role) => {
        if (roleHelper.userHasRole(role) && !roleHelper.isInvoicingSystemAdmin()) {
            access = false;
        }
    });

    return access;
};

const beforeRouteChanged = async (to: Route, from: Route, next: any) => {
    const anonymousPaths = ['login', 'error', 'oauth-signin'];
    await profileModule.init();
    const loggedIn = profileModule.isLoggedIn;
    const matchedRoute = to.matched.length > 0 ? to.matched[0].path : to.path;
    const needAuth = anonymousPaths.indexOf(matchedRoute) < 0 && anonymousPaths.indexOf(to.name) < 0;

    if (!loggedIn && needAuth) {
        return next(`/login?redirectUrl=${to.path}`);
    }

    if (needAuth) {
        const roleAccess = checkRoleAccess(to.meta.excludedRoles);

        if (!roleAccess) {
            return next({ name: 'error', params: { message: 'Geen toegang tot de pagina.' } });
        }
    }

    next();
};

const onRouteFinished = (to: Route, from: Route) => {
    const meta = to.meta || {};
    Vue.nextTick(() => {
        document.title = 'Invoicing Middleware - ' + (meta.title || meta.scope);
        navigationHelper.loadRouteMeta(meta, to.params);
    });

    if (to.name === 'sync-path-detail' && ecosystemHelper.setupIsDone) {
        const teamId = parseInt(to.params.teamId);
        if (teamId) {
            teamHelper.setTeamByTeamId(teamId);
        }
    }
};

router.beforeEach(beforeRouteChanged);
router.afterEach(onRouteFinished);

export const $router = router;
export default router;
