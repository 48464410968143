import { AxiosHelper } from '../helpers/axiosHelper';
import { BaseService } from './baseService';
import Vue from 'vue';
import { teamHelper } from '@/main';
import to from 'await-to-js';
import Provider from '@/models/Provider';
import OAuthCredentials from '@/pages/settings/OAuthCredentials';
import ConnectionTestResult from '@/models/ApiResults/ConnectionTestResult';

export class ProviderService extends BaseService {
    private managementEndpoint = `${Vue.$env().MiddlewareManagementAPIEndpoint}providers`;
    private apiEndpoint = Vue.$env().MiddlewareAPIEndpoint;

    constructor(axiosHelper: AxiosHelper) {
        super(axiosHelper);
    }

    public async getProviders(teamId?: number): Promise<Provider[]> {
        if (!teamId) {
            teamId = teamHelper.currentTeam.id;
        }

        const [err, response] = await to(
            this.get<Provider[]>(`${this.managementEndpoint}`, [['team', teamId]]),
        );
        if (err) {
            return [];
        }

        return response.data.map((x) => new Provider(x));
    }

    public updateProvider(provider: Provider): Promise<unknown> {
        return this.put(`${this.managementEndpoint}/:provider`, provider, [['provider', provider.id]]);
    }

    public createProvider(provider: Provider): Promise<unknown> {
        return this.post(`${this.managementEndpoint}`, provider);
    }

    public updateCredentials(provider: Provider, credentials: any): Promise<unknown> {
        return this.post(`${this.managementEndpoint}/:provider/credentials`, credentials, [['provider', provider.id]]);
    }

    public authorizeProvider(provider: Provider, credentials: OAuthCredentials): Promise<any> {
        return this.post(`${this.managementEndpoint}/:provider/authorize`, credentials, [['provider', provider.id]]);
    }

    public async testProvider(provider: Provider): Promise<ConnectionTestResult> {
        const [_, response] = await to(
            this.get<ConnectionTestResult>(`${this.apiEndpoint}providers/:provider/test-connection`, [['provider', provider.id]])
        );
        return response.data;
    }
}
