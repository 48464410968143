import Vue from 'vue';
import Consumer from './Consumer';
import { consumerModule } from '@/store/Consumer';

export default class PageRender extends Vue {
    public section = 'directory';
    public dirty: boolean = false;
    public editMode?: boolean;
    private notificationGroup = 'notifications';

    get selectedConsumer(): Consumer {
        return consumerModule.find(this.$route.params.consumer);
    }

    public getProviderName(providerId) {
        const provider = consumerModule.currentProviders.find((x) => x.id === providerId);
        return provider ? provider.name : 'No provider';
    }

    /** Notifications */
    public showValidationErrors(errors): void {
        const errorsHtml = errors
            .map((item) => {
                return '<li>' + item + '</li>';
            })
            .join('');

        // First clean all previous errors
        this.clearNotifications();
        this.$notify({
            group: this.notificationGroup,
            title: 'Het opslaan is niet gelukt, verbeter alstublieft de volgende waarschuwingen:',
            text: '<ul>' + errorsHtml + '</ul>',
            type: 'error',
            duration: -1,
        });
    }

    public showFailedResponse(error, consoleError): void {
        if (consoleError !== null) {
            console.error(consoleError);
        }

        this.showError(error);
    }

    public showPending(message): void {
        this.$notify({
            group: this.notificationGroup,
            title: message,
            text:
                '<div class="d-flex justify-content-center">' +
                '<div class="spinner-border" style="width: 2rem; height: 2rem;">' +
                '<span class="sr-only">Loading...</span>' +
                '</div>' +
                '</div>',
            type: 'info',
            duration: -1,
        });
    }

    public showError(error): void {
        this.$notify({
            group: this.notificationGroup,
            title: 'Oh snap! Er is iets misgegaan...',
            text: error,
            type: 'error',
            duration: -1,
        });
    }

    public showWarning(warning): void {
        this.$notify({
            group: this.notificationGroup,
            title: `Better check that thingy, this isn't valid...`,
            text: warning,
            type: 'warning',
            duration: -1,
        });
    }

    public showSuccess(title: string, text?: string, duration?: number): void {
        this.$notify({
            group: this.notificationGroup,
            title: title,
            text: text,
            type: 'success',
            duration: duration
        });
    }

    public showInfo(message) {
        this.$notify({
            group: this.notificationGroup,
            title: message,
            type: 'primary',
        });
    }

    public clearNotifications(): void {
        this.$notify({
            group: this.notificationGroup,
            clean: true,
        });
    }

    public clearAndShowSuccess(message): void {
        this.clearNotifications();
        this.showSuccess(message);
    }

    public clearAndShowError(error, consoleError = null): void {
        if (consoleError !== null) {
            console.error(consoleError);
        }

        this.clearNotifications();
        this.showError(error);
    }

    public clearAndShowWarning(warning) {
        this.clearNotifications();

        Vue.nextTick(() => {
            this.showWarning(warning);
        });
    }

    public fitElement(el: any, margin: number = 75, minHeight: number = 450): any {
        const innerHeight = window ? window.innerHeight : 0;
        const height = innerHeight - el.offsetTop - margin;

        el.style.height = height > minHeight ? `${height}px` : `${minHeight}px`;
    }

    public getStatusLabel(groupedProviders) {
        const successTotal = groupedProviders.filter((x) => x.status === 2).length;
        return successTotal > 0 ? 'warning' : 'danger';
    }

    public getNumberOfMessages(groupedProviders) {
        return groupedProviders.reduce((a, b) => {
            return a + b.messages.length;
        }, 0);
    }

    public groupBy(arr, prop) {
        const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
        arr.forEach((obj) => map.get(obj[prop]).push(obj));
        return Array.from(map.values());
    }

    public setConsumer() {
        if (this.$route.params.consumer) {
            const consumer = consumerModule.find(this.$route.params.consumer);
            consumerModule.setCurrentConsumer(consumer);
        }
    }

    protected validateObject(name: string = '') {
        if (name.indexOf('.') > -1) {
            const parts = name.split('.');
            if (!this.$v || !this.$v[parts[0]] || !this.$v[parts[0]][parts[1]]) {
                console.error('Cannot check if object is valid!');
            }

            this.dirty = true;
            if (this.$v[parts[0]][parts[1]].$invalid) {
                this.clearAndShowError('Niet alle verplichten zijn ingevuld.');
            }

            return !this.$v[parts[0]][parts[1]].$invalid;
        }

        if (!this.$v || !this.$v[name]) {
            console.error('Cannot check if object is valid!');
        }

        this.dirty = true;
        if (this.$v[name].$invalid) {
            this.clearAndShowError('Niet alle verplichten zijn ingevuld.');
        }

        return !this.$v[name].$invalid;
    }

    get isInvoicesSection() {
        return this.$route.meta.section === 'invoices';
    }
}
