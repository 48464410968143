import Component from 'vue-class-component';
import Vue from 'vue';
import { navigationHelper } from '@/main';
import Profile from './profile';

@Component({
    components: {
        Profile,
    },
})
export default class BarComponent extends Vue {
    public toggleNavMenu() {
        navigationHelper.toggleNavigation();
    }

    get isInvoicesSection() {
        return this.$route.meta.section === 'invoices';
    }
}
