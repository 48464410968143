import Vue from 'vue';
import moment from 'moment';

export class PeriodFilterHelper extends Vue {
    public selectedPeriod: string = '2019-01';

    public periodChangedEvent(data: any): any {
        this.$emit('onPeriodChanged', data);
    }

    public previousMonth(current = ''): string {
        const now = moment(current, 'YYYY-MM');
        const previous = now.subtract(1, 'month');

        return previous.format('YYYY-MM');
    }
}
