import { IProviderConfiguration } from '../IProviderConfiguration';

export default class Unit4OutCustomerInvoices implements IProviderConfiguration {

    public Environment: string = 'Sandbox';

    public isOAuth2Provider(): boolean {
        return true;
    }

    public getApplicationName(): string {
        return this.Environment === 'Sandbox'
        ? 'unit4-sandbox'
        : 'unit4';
    }

    public getAuthorizeUri(clientId: string, redirectUri: string): string {
        const scope = 'http://UNIT4.Multivers.API/Web/WebApi/*';
        const authorizeEndpoint = this.Environment === 'Sandbox'
        ? 'https://sandbox-api.multivers.nl/V221/OAuth/Authorize'
        : 'https://api.multivers.nl/V221/OAuth/Authorize';

        return `${authorizeEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`;
    }

    public needsExtraConnectionContext(): boolean {
        return false;
    }
}
