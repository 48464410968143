import { Component, Prop } from 'vue-property-decorator';
import Path from '@/models/Path';
import { consumerModule } from '@/store/Consumer';
import Provider from '@/models/Provider';
import BasePagePath from '@/models/BasePagePath';
import PathScheduleRuntime from '@/models/PathScheduleRuntime';

@Component
export default class PathInfoComponent extends BasePagePath {
    @Prop() public path?: Path;
    @Prop() public editMode?: boolean;

    public get input(): Provider {
        const provider = consumerModule.currentProviders.find((p: Provider) => {
            return p.id === this.path.input;
        });
        return provider;
    }

    public get sortedRunTimes() {
        return this.path.schedule.runTimes.sort((a: PathScheduleRuntime, b: PathScheduleRuntime) => {
            if (a.hour === b.hour) {
                return a.minute < b.minute ? -1 : 1;
            }
            return a.hour < b.hour ? -1 : 1;
        });
    }

    public get viewRunTimes(): string[] {
        return this.sortedRunTimes.map((runTime: PathScheduleRuntime) => {
            return `${runTime.hour.toString().padStart(2, '0')}:${runTime.minute.toString().padStart(2, '0')}`;
        });
    }

    public getTeamName(teamId: number) {
        const team = this.teamList.find((x) => x.id === teamId);

        if (team) {
            return team.name;
        }

        return 'N/A';
    }

    public addRunTime() {
        this.path.schedule.runTimes.push(new PathScheduleRuntime());
    }

    public removeRunTime(r: number) {
        this.path.schedule.runTimes.splice(r, 1);
    }

    public lookBackUpdated(value: string) {
        if (+value > 90) {
            this.path.lookBackDays = 90;
        }

        if (+value < 0) {
            this.path.lookBackDays = 0;
        }

        if (value === '') {
            this.path.lookBackDays = null;
        }
    }
}
