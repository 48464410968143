import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridFunctionComponent extends Vue {
    @Prop({ default: 0 }) public value: number;
    @Prop({ default: () => null }) public action: any;
    @Prop({ default: () => null }) public dataItem: any;

    public getStatusColor() {
        // all failed
        if (this.dataItem.successProviders === 0 && this.dataItem.triedProviders > 0) {
            return 'bg-danger';
        }

        // partial failed
        if (this.dataItem.successProviders > 0 && this.dataItem.triedProviders > 0 && this.dataItem.successProviders < this.dataItem.triedProviders) {
            return 'bg-warning';
        }

        // no actions tried
        if (this.dataItem.successProviders === 0 && this.dataItem.triedProviders === 0) {
            return 'bg-secondary';
        }

        return 'bg-success';
    }
}
