import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { ecosystemHelper, teamHelper } from '@/main';
import { Membership } from '@/models/Interfaces';
import { Route } from 'vue-router';
import { profileModule } from '@/store/profile';
import { consumerModule } from '@/store/Consumer';

@Component
export default class NavigationTeam extends Vue {
    public isEcoScope: boolean = false;

    public created() {
        this.isEcoScope = this.$route.meta.scope === 'ecosystem';
    }

    public updated() {
        this.isEcoScope = this.$route.meta.scope === 'ecosystem';
    }

    public currentTeam() {
        const profile = profileModule.brightProfile;
        if (profile && profile.teamMemberships) {
            return profile.teamMemberships.find((membership: Membership) => {
                return membership.key === teamHelper.currentTeam.key;
            });
        }

        return {} as Membership;
    }

    public get otherTeams(): any[] {
        const profile = profileModule.brightProfile;
        if (!profile || !profile.teamMemberships.filter((x) => x.ecosystem === ecosystemHelper.currentEcosystem.id)) {
            return [];
        }

        return profile.teamMemberships.filter((x) => x.ecosystem === ecosystemHelper.currentEcosystem.id);
    }

    public async switchTo(team: Membership): Promise<Route> {
        teamHelper.setTeamByTeamId(team.id);
        await consumerModule.fetchPaths();

        const route = this.$route;
        const params = Object.assign({}, route.params);
        params.teamKey = team.key;
        params.teamId = team.id.toString();

        return this.$router.push({
            name: route.name === 'sync-paths' ? 'sync-paths-team' : route.name,
            params,
        });
    }
}
