import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { $router, ecosystemHelper, loginHelper, meService, teamHelper } from './main';
import { Person } from './models/Person';
import Team from './models/Team';
import { messageModule } from './store/Messages';
import { consumerModule } from './store/Consumer';
import { profileModule } from './store/profile';
import to from 'await-to-js';

@Component
export default class AppComponent extends Vue {
    public isLoading: boolean = true;
    public teams: Team[] = [];
    public user: Person = null;

    public async beforeMount() {
        await profileModule.init();
        await this.initApp();

        this.isLoading = false;
    }

    public showContent() {
        return profileModule.profileLoaded;
    }

    public showTopBar() {
        return profileModule.profileLoaded;
    }

    public showNav() {
        return profileModule.profileLoaded;
    }

    public get errors() {
        return messageModule.errorMessages;
    }

    get isInvoicesSection() {
        return this.$route.meta.section === 'invoices';
    }

    private async initApp() {
        if (profileModule.isLoggedIn) {
            const [err, reponse] = await to(meService.profile());
            if (err) {
                await $router.push({ name: 'login' });
                this.isLoading = false;
                return loginHelper.logOut();
            }
            profileModule.setBrightProfile(reponse);

            // if (this.$appInsights) {
            //     this.$appInsights.trackEvent({ name: 'user-login-existing-token' });
            // }

            await ecosystemHelper.setup();
            await teamHelper.setup();
            // await translateModule.fetch();
            // await legalEntityModule.init();

            await consumerModule.fetchPaths();
            // await consumerModule.fetchAllPaths();
            await consumerModule.fetchProviders();
            // this.setConsumer();
        }
    }

    // private setConsumer() {
    //     const consumer = this.$route.params.consumer ? consumerModule.find(this.$route.params.consumer) : consumerModule.first;
    //     consumerModule.setCurrentConsumer(consumer);
    // }
}
