import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import store from '../store';

@Module
class MessageModule extends VuexModule {
    private errors: string[] = [];
    private success: string[] = [];

    public get errorMessages() {
        return this.errors;
    }

    public get successMessages() {
        return this.success;
    }

    @Action
    public addError(msg: string) {
        console.error(msg);
        // this.ADD_ERROR(msg);
    }

    @Action
    public addSucces(msg: string) {
        console.info(msg);
        // this.ADD_SUCCESS(msg);
    }

    @Mutation
    private ADD_ERROR(msg: string) {
        this.errors.push(msg);
    }

    @Mutation
    private ADD_SUCCESS(msg: string) {
        this.success.push(msg);
    }
}

export const messageModule = new MessageModule({ store, name: 'messages' });
