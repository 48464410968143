import { LoginService } from '../services/loginService';
import { profileModule } from '@/store/profile';

export class LoginHelper {
    private loginService: LoginService;

    constructor(loginService: LoginService) {
        this.loginService = loginService;
    }

    public async logOut() {
        await this.loginService.logout();
    }

    public isLoggedIn() {
        return profileModule.isLoggedIn;
    }

    public getUser() {
        return profileModule.profile;
    }
}
