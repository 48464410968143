/**
 * Different types of roles used within the application.
 */
export enum UserRoles {

    /**
     * Has global wide 'superuser' rights.
     */
    SiteAdmin = 'SiteAdmin',

    /**
     * Has rights on an account with sites (parks).
     */
    InvoicingSystemAdmin = 'InvoicingSystemAdmin',

    /**
     * Has rights on specific pages of a site.
     */
    InvoicingFinancialEmployee = 'InvoicingFinancialEmployee',
}

/**
 * Target specific role definitions for users.
 */
export default class UserRole {
    public id: number;
    public role: UserRoles;
}
