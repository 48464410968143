import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Grid } from '@progress/kendo-vue-grid';

@Component
export default class BaseFilter extends Vue {
    @Prop() public grid: Grid;
    @Prop() public field: string;
    @Prop() public filterType: string;
    @Prop() public value: any;

    public filterOperator: string = 'contains';
    public filterValue: string = '';

    public mounted() {
        this.filterValue = this.value;
    }

    public change(ev: any) {
        this.$emit('change', {
            operator: this.filterOperator,
            field: this.field,
            value: this.filterValue,
            syntheticEvent: ev,
        });
    }

    public reset(ev: any) {
        this.filterValue = '';
        this.$emit('change', {
            operator: '',
            field: '',
            value: '',
            syntheticEvent: ev,
        });
    }
}
