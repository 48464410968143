import { ecosystemHelper, loginHelper, teamService } from '@/main';
import { consumerModule } from '@/store/Consumer';
import { profileModule } from '@/store/profile';
import { Membership } from '../models/Interfaces';

export class TeamHelper {
    public _currentTeam: Membership = null;
    public setupIsDone: boolean = false;
    public async setup() {
        let team = null;
        const urlSegments = window.location.pathname.split('/');
        if (typeof urlSegments === 'object' && urlSegments.length > 2) {
            team = parseInt(urlSegments[2].split('-')[0]);
        }

        this.setTeamByTeamId(team);
        this.setupIsDone = true;
    }

    public getTeam(id?: number) {
        if (!id && this._currentTeam) {
            id = this._currentTeam.id;
        }
        const user = profileModule.brightProfile;
        let team = user.teamMemberships
            .filter((x) => x.ecosystem === ecosystemHelper.currentEcosystem.id)
            .find((membership) => {
                return membership.id === id;
            });

        if (team) {
            return team;
        }

        team = user.teamMemberships.filter((x) => x.ecosystem === ecosystemHelper.currentEcosystem.id).find((x) => x.id === user.primaryTeam);
        if (team) {
            return team;
        }

        team = user.teamMemberships.filter((x) => x.ecosystem === ecosystemHelper.currentEcosystem.id)[0];

        if (team) {
            return team;
        }

        return user.teamMemberships[0];
    }

    public setTeamByTeamId(id: number) {
        this._currentTeam = this.getTeam(id);
        consumerModule.fetchPaths();
        consumerModule.fetchProviders();
    }

    public setTeam(team: Membership) {
        this.setTeamByTeamId(team.id);
    }

    public getTeamId(id?: number): any {
        const team = this.getTeam(id);
        return team.id;
    }

    public getTeamName(id: number): any {
        const user = loginHelper.getUser();
        const team = user.teamMemberships.find((membership) => {
            return membership.id === id;
        });

        if (team) {
            return team.name;
        }

        return user.teamMemberships[0].name;
    }

    public async getTeamMembers(teamId: number) {
        if (this._currentTeam.members && this._currentTeam.members.length > 0 && this._currentTeam.id === teamId) {
            return this._currentTeam.members;
        }

        const members = await teamService.getMembers(teamId);
        if (members && members.length > 0 && this._currentTeam.id === teamId) {
            this._currentTeam.members = members;
        }

        return members;
    }

    public getTeamIdByKey(key?: string) {
        if (!key) {
            key = this._currentTeam.key;
        }

        const user = loginHelper.getUser();
        const team = user.teamMemberships.find((membership) => {
            return membership.key === key;
        });

        if (team) {
            return team;
        }
        return user.teamMemberships[0];
    }

    get currentTeam(): Membership {
        if (this._currentTeam === null) {
            const team = this.getTeam();
            this._currentTeam = team;
        }

        return this._currentTeam;
    }

    get currentTeamId(): number {
        return this._currentTeam.id;
    }

    get teamParam(): string {
        return `${this._currentTeam.id}-${this.currentTeam.key}`;
    }

    public getTeamByKey(key: string): Membership {
        const team = this.getTeamIdByKey(key);
        return team;
    }
}
