import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { consumerModule } from '@/store/Consumer';
import Consumer from '@/models/Consumer';

@Component
export default class ConsumerSelectorComponent extends Vue {
    public async mounted() {
        await consumerModule.fetchIfNeeded();

        if (!this.$route.params.consumer) {
            const first = consumerModule.first;
            if (first) {
                this.$router.push({ name: 'sync', params: { consumer: first.id } });
            }
        }
    }

    public get consumers() {
        return consumerModule.all;
    }

    public get consumer() {
        return this.consumers.find((c: Consumer) => {
            return c.id === this.$route.params.consumer;
        }) || new Consumer({ name: 'Invalid consumer' });
    }

    public setSelectedConsumer(consumer: Consumer) {
        const params = this.$route.params;
        params.consumer = consumer.id;
        consumerModule.setCurrentConsumer(consumer);
        this.$router.push({ name: this.$route.name, params });
    }
}
