const dictionary = {
    InvoiceImportStatus: {
        0: 'Unknown',
        1: 'Draft',
        2: 'Handled',
        3: 'Failed',
    },
    PaymentStatus: {
        0: 'Unknown',
        1: 'Open',
        2: 'Partial',
        3: 'Full',
    },

    Health: {
        0: 'Unknown',
        1: 'Success',
        2: 'Missing',
        3: 'Incomplete',
        4: 'Not synced',
    },

    StepType: {
        0: 'Unknown',
        1: 'Output',
        2: 'Action',
    },
};

export default dictionary;
