import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { loginService, ecosystemHelper } from '@/main';
import { NetworkStatus } from '../models/NetworkStatus';
import { profileModule } from '@/store/profile';

export class AxiosHelper {
    public axiosResponseInterceptor: number;
    private authAxiosInstance: AxiosInstance = {} as AxiosInstance;
    private publicAxiosInstance: AxiosInstance = {} as AxiosInstance;

    constructor(authConfig: AxiosRequestConfig, publicConfig: AxiosRequestConfig) {
        const self = this;

        const generalConfig = {
            transformResponse: (data: any) => {
                const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/;

                function dateParser(_, value) {
                    if (typeof value === 'string' && dateFormat.test(value)) {
                        return new Date(value);
                    }

                    return value;
                }

                if (data.constructor === ArrayBuffer) {
                    return data;
                }

                if (data) {
                    return JSON.parse(data, dateParser);
                }
            },
        } as AxiosRequestConfig;

        authConfig = (Object as any).assign({}, generalConfig, authConfig);
        publicConfig = (Object as any).assign({}, generalConfig, publicConfig);

        this.authAxiosInstance = axios.create(authConfig);
        this.publicAxiosInstance = axios.create(publicConfig);

        this.authAxiosInstance.interceptors.request.use(
            async (config) => {
                if (!navigator.onLine) {
                    return Promise.reject(NetworkStatus.Offline);
                }

                if (profileModule.isLoggedIn) {
                    const user = profileModule.user;

                    if (user && user.expired) {
                        await loginService.refreshToken();
                    }

                    const jwt = user.access_token;
                    self.setAuthHeder(jwt);
                    config.headers.Authorization = `Bearer ${jwt}`;
                }

                if (
                    typeof config.headers['x-ecosystem'] === 'undefined' &&
                    ecosystemHelper &&
                    ecosystemHelper.currentEcosystem &&
                    ecosystemHelper.setupIsDone
                ) {
                    config.headers['x-ecosystem'] = ecosystemHelper.currentEcosystem.id;
                }
                return config;
            },
            (error) => {
                // Do something with request error
                console.error('Some error has occured with this request: ', error);
                return Promise.reject(error);
            },
        );
    }

    public getAuthAxiosInstance(): AxiosInstance {
        return this.authAxiosInstance;
    }

    public getPublicAxiosInstance(): AxiosInstance {
        return this.publicAxiosInstance;
    }

    public setAuthHeder(jwt: string): void {
        this.authAxiosInstance.defaults.headers.Authorization = 'Bearer ' + jwt;
    }

    public async setup() {
        if (profileModule.isLoggedIn) {
            const jwt = profileModule.access_token;
            this.setAuthHeder(jwt);
        }
    }
}
