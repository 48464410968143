import { BModal } from 'bootstrap-vue';
import PageRender from '@/models/PageRender';

export default class BaseModal extends PageRender {
    public isLoading: boolean = true;

    public $refs!: {
        baseModal: any;
    };

    public show() {
        this.$refs.baseModal.show();
    }

    public hide() {
        this.$refs.baseModal.hide();
    }
}
