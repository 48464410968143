import { Component, Prop } from 'vue-property-decorator';
import { consumerService, consumerHelper, pathService } from '@/main';
import to from 'await-to-js';
import PageRender from '@/models/PageRender';
import ImportedInvoice from '@/models/ImportedInvoice';
import { Invoice } from '@/models/BrightInvoices/Models';

@Component
export default class InvoiceDetailComponent extends PageRender {
    @Prop() public dataItem: any;
    public editMode: boolean = false;
    public pathId: string = '';
    public lines: any[];

    public async created() {
        if (this.dataItem) {
            if (this.dataItem.invoice) {
                this.lines = this.dataItem.invoice.invoiceLines;
            } else {
                this.lines = this.dataItem.lines;
            }

            this.lines.forEach((line) => {
                if (!line.project) {
                    line.project = { name: '' };
                }

                if (!line.department) {
                    line.department = { name: '' };
                }
            });
        }

        this.pathId = this.$route.params.id;
    }

    public get invoiceLines(): any[] {
        return this.lines;
    }

    public cancel() {
        this.editMode = false;
    }

    public async saveInvoice() {
        this.showPending('Saving invoice..');
        let [err, repsonse] = [null, null];

        if (this.pathId && typeof this.pathId !== 'undefined' && this.pathId !== '') {
            [err, repsonse] = await to(pathService.updateInvoice(this.dataItem, this.pathId));
        } else {
            [err, repsonse] = await to(consumerService.updateInvoice(this.selectedConsumer.id, this.dataItem));
        }
        if (err) {
            return this.clearAndShowError('Failed to save invoice');
        }

        this.clearAndShowSuccess('Invoice saved');
        this.editMode = false;
    }

    public edit() {
        this.editMode = true;
    }
}
