import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class GridButtonComponent extends Vue {
    public content: string = '';
    public function: any;
    public item: any;

    public mounted() {
        this.content = this.$data.templateArgs.content;
        this.function = this.$data.templateArgs.function;
        this.item = this.$data.templateArgs.item;
    }

    public doFunction() {
        this.function(this.item);
    }
}
