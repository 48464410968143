import { loginService } from '@/main';
import BasePage from '@/models/BasePage';
import { Component } from 'vue-property-decorator';

@Component
export default class TopBarProfile extends BasePage {
    public logOut(): void {
        loginService.logout();
        this.$router.push('/login?redirectUrl=%2F');
    }

    public get userName(): string {
        return '';
    }
}
