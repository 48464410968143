import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { navigationHelper } from '@/main';

@Component
export default class TopBarScopeComponent extends Vue {
    @Prop() public size;

    public toggleNavMenu() {
        navigationHelper.toggleNavigation();
    }
}
